import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface CreateSubjectState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: CreateSubjectState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const createSimulationFilter = createAsyncThunk(
  "SimualtionFilter/create",
  async (params: { name: string; labels: string,url:string }, dispatch) => {
    const response = await axios.post(
      "/api/v1/simulationfilters",
      {
        name: params.name,
        labels:params.labels,
        url:params.url
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createSubjectSlice = createSlice({
  name: "Create Subject",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSimulationFilter.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createSimulationFilter.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createSimulationFilter.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createSubjectSlice.reducer;
