import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
  RemoveValueFromLocalStorage,
  SaveValueToLocalStorage,
} from "../../utilities/LocalstorageUtility";
import { User } from "../../@types/User";

interface LoginState {
  loadingState: LoadingState;
  value?: {
    token: string;
    status: number;
    user: User;
  } | null;
  error: any;
}

const initialState: LoginState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const loginUser = createAsyncThunk(
  "Auth/Login",
  async (params: { email: string; password: string }) => {
    const response = await axios.post(`/api/v1/auth/signin-data-entry`, {
      email: params.email,
      password: params.password,
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }
    
    return null;
  }
);
export const authorizeUser = createAsyncThunk(
  "Auth/Authorize",
  async () => {
    const response = await axios.get(`/api/v1/auth/authorizeTokenforDataentry`,{
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }
    if(status===403){
      RemoveValueFromLocalStorage(LocalStorageKey.Token);
      RemoveValueFromLocalStorage(LocalStorageKey.User);
      return null;
    }
    return null;
  }
);

export const loginSlice = createSlice({
  name: "Auth/Login",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      RemoveValueFromLocalStorage(LocalStorageKey.Token);
      RemoveValueFromLocalStorage(LocalStorageKey.User);
      state.value = null;
    },
  },  
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload != null) state.value = action.payload;

        var userResponse = state.value;
        if (userResponse != null) {
          SaveValueToLocalStorage(
            LocalStorageKey.User,
            JSON.stringify(userResponse.user)
          );
          SaveValueToLocalStorage(LocalStorageKey.Token, userResponse.token);
        }
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
