import { Modal } from "@mantine/core";
import { AddQuestionDialog } from "../../Dialogs/AddQuestionDialog";

interface AddQuestionsModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: AddQuestionDialogResultData) => void;
}

export function AddQuestionModal(props: AddQuestionsModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="xl"
      title="Add Question"
      overflow="inside"
      centered
    >
      <AddQuestionDialog onSubmit={props.onSubmit}></AddQuestionDialog>
    </Modal>
  );
}
