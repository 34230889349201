import {
  Container,
  SimpleGrid,
  Modal,
  LoadingOverlay,
  Group,
  Title,
  Button,
  FileInput,
  Stack,
  MultiSelect,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { RoundedCard } from "../components/RoundedCard";
import { fetchChapterById } from "../features/chapters/getChapterByIdSlice";

import { AppDispatch } from "../store/ReduxStore";
import { AddTopicDialog } from "./Dialogs/AddTopic";
import { DeleteConfirmationDialog } from "./Dialogs/DeleteConfirmationDialog";
import { deleteChapterById } from "../features/chapters/deleteChapterSlice";
import { AddChapterDialog } from "./Dialogs/AddChapter";
import { createTopic } from "../features/topics/createTopicSlice";
import { deleteTopicById } from "../features/topics/deleteTopicSlice";
import { updateTopicNameById } from "../features/topics/topicSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
import { AddLessonPlan } from "./Dialogs/AddLessonPlan";
import { AddObjectives } from "./Dialogs/AddObjectives";
import { UploadLessonPlan } from "../features/chapters/chapterSlice";
import { debug } from "console";
import { getSimulationList } from "../features/simulation/getSimulationListSlice";
import { SelectItem } from "./TopicDetails/Modals/AddSimulationToTopicModal";
import { addChapterToSimulation } from "../features/simulation/updateSimulationSlice";

const adminAuthorizationActions = authorizationModel.actions;

interface TopicListParams {
  chapterId?: string;
  onTopicClick: (topicId: string, subjectId: string) => void;
}
export function TopicList(props: TopicListParams) {
  const [topics, setTopics] = useState<{ _id: string; name: string }[]>();
  const [chapter, setChapter] = useState<Chapter>();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [showAddLessonPlan, SetShowAddLessonPlan] = useState<boolean>(false);
  const [showObjectives, SetShowObjectives] = useState<boolean>(false);
  const [fileUploadUrl, setFileUploadUrl] = useState<string | null>(null);
  const [simulationtagModal, setSimulationTagsModal] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [simulations, setSimulations] = useState<SimulationListData[]>([]);
  const [selectedSimulations, setSelectedSimulations] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [subjectId, setSubjectId] = useState<string>("");

  const dispath = useDispatch<AppDispatch>();
  useEffect(() => {
    fetchSimulationList();
  }, []);
  function fetchSimulationList() {
    setLoading(true);
    dispath(getSimulationList())
      .unwrap()
      .then((data: any) => {
        setSimulations([]);
        const simulationListData: SimulationListData[] = [];
        data.forEach((item: any) => {
          simulationListData.push({
            _id: item._id,
            label: item.name,
            thumbnailUrl: item.thumbnailImageUrl,
            value: item._id,
            description: item.description,
            simultionfilters: item.simultionfilters,
            isActivity: item.isActivity,
            isSimulation: item.isSimulation,
            simulationDescription: item.simulationDescription,
            simulationfeatures: item.simulationfeatures,
            simulationTags: item.simulationTags,
            simulationBackgroundColor: item.simulationBackgroundColor,
            isSimulationPremium: item.isSimulationPremium,
            videoUrl: item.videoUrl,
          });
        });
        setSimulations(simulationListData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  const subjectCards = topics?.map((item) => (
    <RoundedCard
      title={item.name}
      key={item._id}
      onClick={() => props.onTopicClick(item._id, subjectId)}
      showMenuButton={true}
      onDeleteClick={() => {
        DidConfirmDelete(item._id);
      }}
      onRenameSubmit={(val) => DidConfirmRename(item._id, val)}
    ></RoundedCard>
  ));

  useEffect(() => {
    fetchData();
  }, []);

  function DidConfirmRename(_id: string, name: string) {
    if (_id == null) return;
    setLoadingData(true);
    dispatch(updateTopicNameById({ id: _id, name: name }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setTopics((prev) => {
          const prev1 = prev?.map((x) => {
            if (x._id === data._id) {
              return data;
            }
            return x;
          });
          return prev1;
        });
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }
  function DidConfirmDelete(_id: string) {
    setShowDeleteDialog(false);
    if (_id == null) return;
    setLoadingData(true);
    dispatch(deleteTopicById(_id))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setTopics((prev) => {
          const prev1 = prev?.filter((x) => {
            return x._id !== _id;
          });
          return prev1;
        });
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }
  function DidClickSubmit(topicName: string, order: string) {
    setShowDialog(false);
    if (props.chapterId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(
      createTopic({
        chapterId: props.chapterId,
        name: topicName,
        order: Number(order),
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  function fetchData() {
    if (props.chapterId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(fetchChapterById(props.chapterId))
      .unwrap()
      .then((data: Chapter) => {
        console.log(data);
        setSubjectId(data.subjectId);
        setLoadingData(false);
        setChapter(data);
        setTopics(data.topics);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }

  function OnDeleteChapterClick() {
    setShowDeleteDialog(false);

    if (props.chapterId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(deleteChapterById(props.chapterId))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        navigate(-1);
      })
      .catch((error) => {
        dispatch(adminAuthorizationActions.setModal(true));
        setLoadingData(false);
      });
  }

  function onChapterUpdateClick(name: string, order: string) {
    setShowUpdateDialog(false);
  }

  return (
    <Container size="xl">
      <Group position="apart" p="xl">
        <Title>{chapter?.name}</Title>
        <Group>
          <Button
            variant="outline"
            color="red"
            onClick={() => SetShowObjectives(true)}
          >
            Add Objectives
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => SetShowAddLessonPlan(true)}
          >
            Add Lesson Plan
          </Button>
          <Button
            variant="outline"
            color="blue"
            onClick={() => navigate(`/editChapterIntro/${props.chapterId}`)}
          >
            Edit Introduction
          </Button>
          <Button
            variant="outline"
            color="green"
            onClick={() => setShowDialog(true)}
          >
            Add Topic
          </Button>
          <Button
            variant="outline"
            color="blue"
            onClick={() => setShowUpdateDialog(true)}
          >
            Update Chapter
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => setShowDeleteDialog(true)}
          >
            Delete Chapter
          </Button>
        </Group>
        <Button
          variant="outline"
          color="blue"
          onClick={() => {
            setSimulationTagsModal(true);
          }}
        >
          Create Simulation Tags
        </Button>
      </Group>

      {/* All Topics */}
      <Title p="xl">All Topics</Title>
      <SimpleGrid cols={3}>{subjectCards}</SimpleGrid>
      <Modal
        title={"Add Objectives"}
        opened={showObjectives}
        onClose={() => SetShowObjectives(false)}
      >
        <AddObjectives
          showModal={SetShowObjectives}
          chapter={chapter!}
        ></AddObjectives>
      </Modal>
      <Modal
        title={"Add lesson plan"}
        opened={showAddLessonPlan}
        onClose={() => SetShowAddLessonPlan(false)}
      >
        <AddLessonPlan
          showModal={SetShowAddLessonPlan}
          chapterId={props.chapterId!}
        ></AddLessonPlan>
      </Modal>
      <Modal
        centered
        opened={showDialog}
        onClose={() => setShowDialog(false)}
        title="Add Chapter"
        size="xl"
        overflow="inside"
      >
        <AddTopicDialog onSubmitClick={DidClickSubmit} />
      </Modal>
      <Modal
        centered
        opened={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title={`Are you sure you want to delete ${chapter?.name}?`}
        style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
      >
        <DeleteConfirmationDialog
          onConfirmClick={OnDeleteChapterClick}
          onCancelClick={() => {
            setShowDeleteDialog(false);
          }}
        />
      </Modal>

      <Modal
        centered
        opened={showUpdateDialog}
        onClose={() => setShowUpdateDialog(false)}
      >
        <AddChapterDialog
          chapterName={chapter?.name}
          order={String(chapter?.sortOrder)}
          onSubmitClick={onChapterUpdateClick}
        ></AddChapterDialog>
      </Modal>
      <Modal
        opened={simulationtagModal}
        onClose={() => {
          setSimulationTagsModal(false);
        }}
        title="SimTags"
        size={"xl"}
      >
        <Stack>
          <MultiSelect
            placeholder="Pick all you like"
            itemComponent={SelectItem}
            data={simulations}
            searchable
            nothingFound="Nobody here"
            onChange={setSelectedSimulations}
            maxDropdownHeight={500}
          />
          <Button
            fullWidth
            onClick={() => {
              setLoading(true);
              if (chapter) {
                addChapterToSimulation({
                  SimulationIds: selectedSimulations,
                  chapterName: chapter?.name.toLowerCase(),
                  chapterId: chapter?._id,
                })
                  .then(() => {
                    setLoading(false);
                  })
                  .catch((e) => {
                    setLoading(false);
                    console.log(e);
                  });
              }
              setSimulationTagsModal(false);
            }}
          >
            Submit
          </Button>
        </Stack>
      </Modal>
      <LoadingOverlay visible={loadingData}></LoadingOverlay>
    </Container>
  );
}
