import { Center, Container, Title, useMantineTheme } from "@mantine/core";
import { useCallback, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

export function UnitySimulation() {
  const localStorageData = GetValueFromLocalStorage<string>(
    LocalStorageKey.SimulationData
  );
  const theme = useMantineTheme();
  const simulationData: SimulationData = JSON.parse(localStorageData ?? "");
  const { unityProvider, isLoaded, loadingProgression, sendMessage } =
    useUnityContext({
      loaderUrl: simulationData.loaderUrl,
      dataUrl: simulationData.dataUrl,
      frameworkUrl: simulationData.frameworkUrl,
      codeUrl: simulationData.wasmUrl,
    });

  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const loadingPercentage = Math.round(loadingProgression * 100);

  const handleChangePixelRatio = useCallback(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );
  return (
    <Container
      p={0}
      m={0}
      fluid={true}
      style={{
        minHeight: "100vh",
      }}
    >
      {isLoaded === false && (
        <Center
          style={{
            minHeight: "100vh",
          }}
        >
          <Title>Loading... ({loadingPercentage}%)</Title>
        </Center>
      )}
      <Unity
        unityProvider={unityProvider}
        style={{ height: "100vh", width: "100vw" }}
        devicePixelRatio={devicePixelRatio}
      />
    </Container>
  );
}
