import { Button, Stack, TextInput, useMantineTheme } from "@mantine/core";
import { useState } from "react";

interface AddVideoURLDialogProps {
  onSubmitClick: (url: string) => void;
}

export function AddVideoURLDialog(props: AddVideoURLDialogProps) {
  const [url, SetUrl] = useState<string>();
  const theme = useMantineTheme();

  return (
    <Stack>
      <TextInput
        withAsterisk
        value={url}
        onChange={(e) => SetUrl(e.target.value)}
      />

      <Button
        fullWidth
        onClick={() => props.onSubmitClick(url ?? "")}
        style={{ backgroundColor: theme.colors.green[3] }}
      >
        Submit
      </Button>
    </Stack>
  );
}
