import { useEffect, useState } from "react";

import { CreateBoard, getAllBoards } from "../features/boardSlice";
import {
  Box,
  Button,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

export function Board() {
  const [boards, setBoards] = useState<any>([]);
  const [createModalOpened, setModalOpened] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const navigate = useNavigate();
  function fetchAllBoards() {
    getAllBoards()
      .then((data: any) => {
        if (data) setBoards(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchAllBoards();
  }, []);
  return (
    <>
      <Box m={20}>
        <Button
          onClick={() => {
            setModalOpened(true);
          }}
        >
          Add Board
        </Button>
        <Modal
          opened={createModalOpened}
          onClose={() => setModalOpened(false)}
          centered
          title="Create Board"
        >
          <>
            <TextInput
              label="Board Name"
              withAsterisk
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <Button
              onClick={() => {
                setModalOpened(false);
                CreateBoard(newName).then(() => {
                  fetchAllBoards();
                });
              }}
            >
              Submit
            </Button>
          </>
        </Modal>
        <SimpleGrid cols={3} mt={50} mx={20}>
          {boards.map((board: any) => {
            return (
              <Button
                p={0}
                onClick={() => {
                  navigate(`/board/${board._id}`);
                }}
              >
                {board.name}
              </Button>
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
}
