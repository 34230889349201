import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface UpdateClassByIdState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: UpdateClassByIdState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const updateClassById = createAsyncThunk(
  "classes/Updte",
  async (params: { classId: string; className: string; order: number }) => {
    const response = await axios.put(
      `/api/v1/class/${params.classId}`,
      {
        name: params.className,
        sortOrder: params.order,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const updateClassByIdSlice = createSlice({
  name: "Update Class Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateClassById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(updateClassById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(updateClassById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default updateClassByIdSlice.reducer;
