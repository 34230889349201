import {
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  SimpleGrid,
  Space,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AddClassDialog } from "../components/Dialogs/AddClassDialog";
import { AddSubjectDialog } from "../components/Dialogs/AddSubjectDialog";
import { DeleteConfirmationDialog } from "../components/Dialogs/DeleteConfirmationDialog";
import { RoundedCard } from "../components/RoundedCard";
import { TitleWithMoreButton } from "../components/TitleWithMoreButton";
import { deleteChapterById } from "../features/chapters/deleteChapterSlice";
import { deleteClassById } from "../features/classes/deleteClassByIdSlice";
import { getClassById } from "../features/classes/getClassSlice";
import { updateClassById } from "../features/classes/updateClassSlice";
import { createSubject } from "../features/subjects/createSubjectSlice";
import { deleteSubjectById } from "../features/subjects/deleteSubjectSlice";
import { fetchSubjectList } from "../features/subjects/subjectSlice";
import { AppDispatch } from "../store/ReduxStore";
import { updateSubjectById } from "../features/subjects/updateSubjectSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
const adminAuthorizationActions=authorizationModel.actions;

export function Subjects() {
  const params = useParams();
  let classId = params.classId;

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [subjects, setSubjects] = useState<Subject[]>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [classModel, setClassModel] = useState<ClassModel>();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const subjectCards = subjects?.map((item) => (
    <RoundedCard
      key={item._id}
      title={item.name}
      subTitle={`Chapters: ${item.chapters.length}`}
      onClick={() => onSubjectClick(item)}
      backgroundColor={theme.colors.teal[3]}
      showMenuButton={true}
      onDeleteClick={() => DeleteSubject(item)}
      onRenameSubmit={(val)=>{
        DidConfirmRename({
          _id:item._id,
          name:val,
          order:item.sortOrder,
          simulaitonFilter:item.simulationFilter
        })
      }}
    ></RoundedCard>
  ));
  
  function DidConfirmRename(data:{_id:string,name:string,order:number,simulaitonFilter:string}){
    if (data._id == null) return;
    setLoadingData(true);
    dispatch(updateSubjectById({id:data._id,name:data.name,order:data.order,simulationFilter:data.simulaitonFilter}))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setSubjects((prev)=>{
          const prev1=prev?.map((x)=>{
            if(x._id===data._id){
              return data;
            }
            return x;
          })
          return prev1;
        })
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }
  function onSubjectClick(subject: Subject) {
    navigate(`/subject/${subject._id}`);
  }

  useEffect(() => {
    // fetchData();
    fetchClassData();
  }, []);

  function DidClickSubmit(subjectName: string, order: string,filter:string) {
    setShowDialog(false);
    if (classId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(createSubject({ subjectName: subjectName, classId: classId,simulationFilter:filter }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      });
  }

  function DeleteSubject(subject: Subject) {
    setLoadingData(true);
    dispatch(deleteSubjectById(subject._id))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((err)=>{
        dispatch(adminAuthorizationActions.setModal(true))
        setLoadingData(false);
      })
  }

  function fetchData() {
    if (classId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(fetchSubjectList(classId))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setSubjects(data);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }

  function fetchClassData() {
    if (classId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(getClassById(classId))
      .unwrap()
      .then((data: ClassModel) => {
        setLoadingData(false);
        setClassModel(data);
        fetchData();
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }

  function onDeleteClassConfirm() {
    setShowDeleteDialog(false);
    if (classId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(deleteClassById(classId))
      .unwrap()
      .then(() => {
        console.log("Deleted");
        setLoadingData(false);
        navigate(-1);
      })
      .catch((error) => {
        setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true))
        console.log(error);
      });
  }

  function OnUpdateClick(className: string, order: string) {
    setShowUpdateDialog(false);
    if (classId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(
      updateClassById({
        classId: classId,
        className: className,
        order: Number(order),
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setClassModel(data);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  return (
    <Container size="xl">
      {/* Class Details */}

      <Group position="apart" p="xl">
        <Title>{classModel?.name}</Title>
        <Group>
          <Button
            variant="outline"
            color="green"
            onClick={() => setShowDialog(true)}
          >
            Add Subject
          </Button>
          <Button
            variant="outline"
            color="blue"
            onClick={() => setShowUpdateDialog(true)}
          >
            Update Class
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => setShowDeleteDialog(true)}
          >
            Delete Class
          </Button>
        </Group>
      </Group>

      <Title p="xl">All Subjects</Title>
      <SimpleGrid cols={3}>{subjectCards}</SimpleGrid>
      <Modal
        centered
        opened={showDialog}
        onClose={() => setShowDialog(false)}
        title="Add Subject"
      >
        <AddSubjectDialog
          onSubmitClick={(subjectName, order,filter) =>
            DidClickSubmit(subjectName, order,filter)
          }
        />
      </Modal>
      <Modal
        centered
        opened={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title={`Are you sure you want to delete ${classModel?.name}?`}
        style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
      >
        <DeleteConfirmationDialog
          onConfirmClick={onDeleteClassConfirm}
          onCancelClick={() => {
            setShowDeleteDialog(false);
          }}
        />
      </Modal>

      <Modal
        centered
        opened={showUpdateDialog}
        onClose={() => setShowUpdateDialog(false)}
      >
        <AddClassDialog
          onSubmitClick={OnUpdateClick}
          prefilledClassName={classModel?.name}
          sortOrder={String(classModel?.sortOrder)}
          buttonTitle="Update"
        ></AddClassDialog>
      </Modal>
      <LoadingOverlay visible={loadingData}></LoadingOverlay>
      
    </Container>
  );
}
