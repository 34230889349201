import { Modal } from "@mantine/core";
import { AddSimulationDataDialog } from "../Dialogs/AddSimulationData";
import { Add3dSimulation } from "../Dialogs/Add3dSimulation";


interface AddSimulationDataModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

export function Three3DSimualiton(props: AddSimulationDataModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="lg"
      title="Add 3d simualtion Data"
      overflow="inside"
      centered
    >
      <Add3dSimulation onSubmitClick={props.onSubmit} />
    </Modal>
  );
}
