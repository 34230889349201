import {
    Modal,
    Image,
    createStyles,
    Button,
    LoadingOverlay,
    Stack,
    TextInput,
    Group,
    Text
  } from "@mantine/core";
  import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
  import { Fragment, useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import { uploadFile } from "../../../features/uploadFile/uploadFileSlice";
  import { TextEditorModal } from "./TextEditorModal";
  import { AppDispatch } from "../../../store/ReduxStore";
  import { stateToHTML } from "draft-js-export-html";
import { stateFromMarkdown } from "draft-js-import-markdown";
  const useStyles = createStyles((theme, _params, getRef) => {
      return {
        dropZone: {
          height: 100,
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 0,
          backgroundColor: theme.colors.gray[0],
    
          "&[data-accept]": {
            color: theme.white,
            backgroundColor: theme.colors.blue[6],
          },
    
          "&[data-reject]": {
            color: theme.white,
            backgroundColor: theme.colors.red[6],
          },
        },
      };
    });
  interface EditBlogModalProps {
      blog:Blog | null
      setEditBlog:(data:boolean)=>void
      setData:(data:any)=>void
  }
  export function EditBlogModal(props: EditBlogModalProps) {
    const { classes, theme } = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string>(props.blog?.imgUrl ?? ""); 
    const [title,setTitle]=useState<string>(props.blog?.title ?? ""); 
    const [imgUrl,setImgUrl]=useState<string>(props.blog?.imgUrl ?? "");
    
    
    const dispatch = useDispatch<AppDispatch>();
  
    useEffect(() => {
      if(props.blog)
        {setTitle(props.blog?.title)
          setImagePreview(props.blog.imgUrl)}
      }, [props.blog]);

    function OnImageChange(file: File) {
      let reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setImagePreview(reader.result ?? "");
          setFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
    function DidClickSubmit() {
      if (file == null) return;
      setLoading(true);
      dispatch(uploadFile({ file: file }))
        .unwrap()
        .then((data:any) => {
          console.log(data.url)
          setLoading(false);
          setFile(null);
          setImgUrl(data.url)
        })
        .catch((error:any) => {
          setLoading(false);
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    }
    return (
  <Fragment>
      <Stack>
      <LoadingOverlay
          visible={loading}
      />
        <TextInput 
          label={"Title of Blog"}
          value={title}
          onChange={(eve)=>{
              setTitle(eve.currentTarget.value)
          }}
        />
        <Group
          position="apart"
        >
        <Dropzone
          onDrop={(files) => {
            OnImageChange(files[0]);
          }}
          accept={IMAGE_MIME_TYPE}
          className={classes.dropZone}
        >
          <Text>Upload Image Here</Text>
          {imagePreview && (
            <Image
              src={imagePreview}
              fit="contain"
              height={100}
              width={100}
              style={{
                backgroundColor: theme.colors.gray[0],
              }}
            />
          )}
        </Dropzone>
        <Button  onClick={DidClickSubmit}>
          Upload
        </Button>
        </Group>
        <Button
          onClick={()=>{
              props.setEditBlog(true);
              props.setData({title:title,imgUrl})
          }}
        >
          Next
        </Button>
      </Stack>
  </Fragment>
    );
  }
  