import {
  Button,
  Center,
  Checkbox,
  Flex,
  LoadingOverlay,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { getAllBoards } from "../features/boardSlice";
import { useDispatch } from "react-redux";
import {
  CopyQuestions,
  fetchChapters,
} from "../features/chapters/chapterSlice";
import { AppDispatch } from "../store/ReduxStore";
import { fetchSubjectList } from "../features/subjects/subjectSlice";
import { fetchClassList } from "../features/classes/classSlice";
import {
  DifficultyLevel,
  DifficultyLevelValues,
  allQuestionTypesValues,
} from "../@types/QuestionTypes.d";

function SelectChapters(props: {
  selectedChapter: string | null;
  setSelectedChapter: React.Dispatch<React.SetStateAction<string | null>>;
  text: string;
}) {
  const [selectedBoard, setSelectedBoard] = useState<string | null>("");
  const [boards, setBoards] = useState<any>([]);
  const [chapters, setChapters] = useState<any>([]);
  const [classModel, setClassModel] = useState<
    {
      label: string;
      value: string;
      boardId: string;
    }[]
  >([]);
  const [fiteredclassModel, setFiteredClassModel] = useState<
    {
      label: string;
      value: string;
      boardId: string;
    }[]
  >([]);
  const [selectedClass, setSelectedClass] = useState<string | null>("");
  const [selectedSubject, setSelectedSubject] = useState<string | null>("");
  const [subjects, setSubjects] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectedSubject) {
      dispatch(fetchChapters(selectedSubject))
        .unwrap()
        .then((x) => {
          console.log(x);
          setChapters(x.map((y: any) => ({ label: y.name, value: y._id })));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedSubject]);
  useEffect(() => {
    if (selectedBoard)
      setFiteredClassModel(
        classModel?.filter((x) => x.boardId === selectedBoard)
      );
  }, [selectedBoard]);
  useEffect(() => {
    dispatch(fetchClassList())
      .unwrap()
      .then((x) => {
        const temp: {
          label: string;
          value: string;
          boardId: string;
        }[] = [];
        x.map((y: any) => {
          let temp1 = {
            label: y.name,
            value: y._id,
            boardId: y.boardId,
          };
          temp.push(temp1);
        });
        setClassModel(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (selectedClass !== "" && selectedClass) {
      dispatch(fetchSubjectList(selectedClass))
        .unwrap()
        .then((x) => {
          const temp: {
            label: string;
            value: string;
          }[] = [];
          x.map((y: any) => {
            let temp1 = {
              label: y.name,
              value: y._id,
            };
            temp.push(temp1);
          });
          setSubjects(temp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedClass]);
  function fetchAllBoards() {
    getAllBoards()
      .then((data: any) => {
        if (data) {
          setBoards(
            data.map((x: any) => {
              return {
                label: x.name,
                value: x._id,
              };
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchAllBoards();
  }, []);
  return (
    <Stack>
      <Text fz={40} fw={700}>
        {props.text}
      </Text>
      <Select
        value={selectedBoard}
        onChange={setSelectedBoard}
        data={boards}
        placeholder="Select Board"
      />
      <Select
        value={selectedClass}
        onChange={setSelectedClass}
        data={fiteredclassModel}
        placeholder="Select Class"
      />
      <Select
        value={selectedSubject}
        onChange={setSelectedSubject}
        data={subjects}
        placeholder="Select Subject"
      />
      <Select
        value={props.selectedChapter}
        onChange={props.setSelectedChapter}
        data={chapters}
        placeholder="Select Chapter"
      />
    </Stack>
  );
}

export function TransferQuestions() {
  const [selectedChapter1, setSelectedChapter1] = useState<string | null>("");
  const [selectedChapter2, setSelectedChapter2] = useState<string | null>("");
  const [selectedDifficultyType, setSelectedDifficultyType] = useState<
    string | null
  >(DifficultyLevel.MEDIUM);
  const [selectedDifficultyTypeSource, setSelectedDifficultyTypeSource] =
    useState<string | null>(DifficultyLevel.MEDIUM);
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setValues(allQuestionTypesValues.map((x) => x.value));
  }, [allQuestionTypesValues]);
  const dispatch = useDispatch<AppDispatch>();
  function transferQuestions() {
    if (
      selectedChapter1 &&
      selectedChapter2 &&
      selectedDifficultyType &&
      values.length > 0 &&
      selectedDifficultyTypeSource
    ) {
      setIsLoading(true);
      dispatch(
        CopyQuestions({
          sourceChapterId: selectedChapter1,
          destinationChapterId: selectedChapter2,
          difficulityLevel: selectedDifficultyType,
          attributes: values,
          sourceDifficultyType: selectedDifficultyTypeSource,
        })
      )
        .unwrap()
        .then((x) => {
          alert(`${x} Questions Transferred Successfully`);
          setIsLoading(false);
          console.log(x);
        })
        .catch((e) => {
          alert("Error in Transferring Questions");
          setIsLoading(false);
          console.log(e);
        });
    }
  }
  return (
    <Center w="100%" h="100vh">
      <LoadingOverlay visible={isLoading} />
      <Stack w="80%" py={100}>
        <Flex>
          <Stack w="40%">
            <Select
              data={DifficultyLevelValues}
              value={selectedDifficultyTypeSource}
              onChange={setSelectedDifficultyTypeSource}
              label="Select Difficulty Type"
            />
            <SelectChapters
              selectedChapter={selectedChapter1}
              setSelectedChapter={setSelectedChapter1}
              text="Select Source Chapter"
            />
          </Stack>
          <Stack px={30}>
            <Select
              data={DifficultyLevelValues}
              value={selectedDifficultyType}
              onChange={setSelectedDifficultyType}
              label="Select Difficulty Type"
            />
            <Checkbox.Group
              value={values}
              onChange={(value) => {
                setValues(value);
              }}
            >
              {allQuestionTypesValues.map((type) => {
                return (
                  <Checkbox
                    key={type.value}
                    value={type.value}
                    label={type.label}
                  />
                );
              })}
            </Checkbox.Group>
            <Button
              onClick={() => {
                transferQuestions();
              }}
              disabled={
                isLoading ||
                !selectedChapter1 ||
                !selectedChapter2 ||
                !selectedDifficultyType ||
                values.length === 0 ||
                selectedChapter1 === selectedChapter2
              }
            >
              Transfer Questions
            </Button>
          </Stack>

          <Stack w="40%">
            <SelectChapters
              selectedChapter={selectedChapter2}
              setSelectedChapter={setSelectedChapter2}
              text="Select Destination Chapter"
            />
          </Stack>
        </Flex>
      </Stack>
    </Center>
  );
}
