import {
  Container,
  Paper,
  Title,
  Text,
  Group,
  Checkbox,
  Button,
  Modal,
  TextInput,
  Textarea,
  Image,
} from "@mantine/core";
import { useState } from "react";
import {
  updateSubjectiveQuestionById,
  updateSubjectiveQuestionImagesById,
} from "../../features/questions/editquestionSLice";

interface SubjectiveQuestionCardProps {
  question: SubjectiveQuestion;
  indexN: number;
}
export function SubjectiveQuestionCard(props: SubjectiveQuestionCardProps) {
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const [editImageModalOpened, setImageEditModalOpened] =
    useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>(props.question.text);
  const [answerText, setAnswerText] = useState<string>(props.question.answer);

  const [questionImage, setquestionImage] = useState<string>(
    props.question.questionImageUrl
  );
  const [answerImage, setAnswerImage] = useState<string>(
    props.question.answerImageUrl
  );
  function editQuestion() {
    updateSubjectiveQuestionById({
      QuestionId: props.question._id,
      questionText: questionText,
      answerText: answerText,
    });
  }
  function editQuestionImage() {
    updateSubjectiveQuestionImagesById({
      QuestionId: props.question._id,
      questionImage: questionImage,
      answerImage: answerImage,
    });
  }
  return (
    <>
      <Paper shadow="xl" p="xl" radius="md" m="lg">
        <Button
          onClick={() => {
            setEditModalOpened(true);
          }}
        >
          Edit
        </Button>
        <Button
          mx={10}
          onClick={() => {
            setImageEditModalOpened(true);
          }}
        >
          Edit Images
        </Button>
        <div>
          <Title style={{ marginBottom: 10 }} order={3}>
            <Text mx={5} span>
              {props.indexN}.
            </Text>
            {props.question.text}
          </Title>
          <Image src={props.question.questionImageUrl} width={"50%"}></Image>
        </div>
        <div>
          <Text>{props.question.answer}</Text>
          <Image src={props.question.answerImageUrl} width={"50%"}></Image>
        </div>
      </Paper>
      <Modal
        opened={editModalOpened}
        centered
        onClose={() => setEditModalOpened(false)}
      >
        <Text>Edit Question</Text>
        <Textarea
          autosize
          placeholder="Question"
          value={questionText}
          onChange={(e) => {
            setQuestionText(e.target.value);
          }}
        ></Textarea>
        <Textarea
          my={30}
          autosize
          placeholder="Answer"
          value={answerText}
          onChange={(e) => {
            setAnswerText(e.target.value);
          }}
        ></Textarea>
        <Button
          onClick={() => {
            editQuestion();
            setEditModalOpened(false);
          }}
        >
          Submit
        </Button>
      </Modal>

      <Modal
        size={"xl"}
        opened={editImageModalOpened}
        centered
        onClose={() => setImageEditModalOpened(false)}
      >
        <Text>Edit Question Image</Text>
        <Textarea
          autosize
          placeholder="Question Image Link"
          value={questionImage}
          onChange={(e) => {
            setquestionImage(e.target.value);
          }}
        ></Textarea>
        <Textarea
          my={30}
          autosize
          placeholder="Answer Image Link"
          value={answerImage}
          onChange={(e) => {
            setAnswerImage(e.target.value);
          }}
        ></Textarea>
        <Button
          onClick={() => {
            editQuestionImage();
            setImageEditModalOpened(false);
          }}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
}
