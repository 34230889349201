import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface CreateChapterState {
  loadingState: LoadingState;
  value?: Chapter;
  error: any;
}

const initialState: CreateChapterState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const createChapter = createAsyncThunk(
  "subject/fetchChapter",
  async (params: { chapterName: string; subjectId: string }) => {
    const response = await axios.post(
      "/api/v1/chapter/create",
      {
        name: params.chapterName,
        subjectId: params.subjectId,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);

export const createChapterSlice = createSlice({
  name: "Create Chapter Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createChapter.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createChapter.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createChapter.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createChapterSlice.reducer;
