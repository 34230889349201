import { Fragment, useEffect, useState } from "react";
import { TopicTheoryModal } from "../components/TopicDetails/Modals/TopicTheoryModal";
import {
  Button,
  Center,
  Flex,
  LoadingOverlay,
  Stack,
  Textarea,
} from "@mantine/core";
import { fetchTopicDetails } from "../features/topics/topicSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import { useNavigate, useParams } from "react-router-dom";
import { addTheoryToTopic } from "../features/topics/addTheoryToTopicSlice";
import { fetchChapterById } from "../features/chapters/getChapterByIdSlice";
import { updateChapterIntoById } from "../features/chapters/updateChapterSlice";
import { IconChevronsDownLeft } from "@tabler/icons";
import { AddNewBlogModal } from "../components/TopicDetails/Modals/AddNewBlogModal";
import { Createblogs } from "../features/blog/blogSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
const adminAuthorizationActions = authorizationModel.actions;

export function AddBlogPage() {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [addblogContent, setaddBlogContent] = useState<boolean>(false);
  const [theoryData, setTheoryData] = useState<string>("");
  const [data1, setData] = useState<any>();
  const navigate = useNavigate();
  async function formHandler(data: {
    title: string;
    imgUrl: string;
    data: string;
    isHtml: boolean;
  }) {
    setLoadingData(true);
    dispatch(
      Createblogs({
        imgUrl: data.imgUrl,
        title: data.title,
        data: data.data,
        isHtml: data.isHtml,
      })
    )
      .unwrap()
      .then((data: any) => {
        setLoadingData(false);
        console.log(data);
      })
      .catch((error: any) => {
        dispatch(adminAuthorizationActions.setModal(true));
        setLoadingData(false);
        console.log(error);
      });
  }

  return (
    <Fragment>
      {!addblogContent && (
        <Flex w={"100%"} h={"100vh"}>
          <Center mx="auto">
            <AddNewBlogModal
              onSubmitHandler={(data) => {
                formHandler({
                  ...data,
                  isHtml:false
                });
              }}
              setaddBlogContent={setaddBlogContent}
              setData={setData}
            />
          </Center>
          <LoadingOverlay visible={loadingData} />
        </Flex>
      )}

      {addblogContent && (
        <Stack h={"100%"} w={"100%"}>
          <TopicTheoryModal
            text={""}
            onSubmit={(data) => {
              formHandler({
                title: data1.title,
                imgUrl: data1.imgUrl,
                data: data,
                isHtml: false,
              });
              setaddBlogContent(false);
            }}
          />
        </Stack>
      )}
      <Textarea
        value={theoryData}
        onChange={(e) => {
          setTheoryData(e.target.value);
        }}
        autosize
      />
      <div dangerouslySetInnerHTML={{ __html: theoryData }} />
      <Button
        onClick={() => {
          formHandler({
            title: data1.title,
            imgUrl: data1.imgUrl,
            data: theoryData,
            isHtml: true,
          });
        }}
      >
        Submit
      </Button>
    </Fragment>
  );
}
