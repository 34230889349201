import { MantineProvider, Text } from "@mantine/core";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChapterDetail } from "./pages/ChapterDetail";
import { Chapters } from "./pages/Chapters";
import { Classes } from "./pages/Classes";
import { Login } from "./pages/login";
import { SimulationPlay as LoadingSimulation } from "./pages/Simulation/LoadingSimulation";
import { UnitySimulation } from "./pages/Simulation/UnitySimulation";
import { Subjects } from "./pages/Subjects";
import { TopicDetail } from "./pages/TopicDetail";
import { store } from "./store/ReduxStore";
import { ProtectedRoute } from "./utilities/ProtectedRoute";
import { UploadWord } from "./pages/UploadWord";
import { EditTheoryPage } from "./pages/EditTheoryPage";
import { EditChapterIntroPage } from "./pages/EditChapterIntroPage";
import { AddBlogPage } from "./pages/AddBlogPage";
import { EditBlogPage } from "./pages/EditBlogPage";
import { Board } from "./pages/Boards";
import { BoardClasses } from "./pages/BoardClasses";
import { BoardSubjects } from "./pages/BoardSubjects";
import { BoardChapters } from "./pages/BoardChapters";
import { UploadWord2 } from "./pages/UploadWord2";
import { TransferQuestions } from "./pages/TransferQuestions";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Classes />
      </ProtectedRoute>
    ),
  },
  {
    path: "/class/:classId",
    element: (
      <ProtectedRoute>
        <Subjects />
      </ProtectedRoute>
    ),
  },
  {
    path: "/subject/:subjectId",
    element: (
      <ProtectedRoute>
        <Chapters />
      </ProtectedRoute>
    ),
  },
  {
    path: "/chapter/:chapterId",
    element: (
      <ProtectedRoute>
        <ChapterDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/topic/:topicId",
    element: (
      <ProtectedRoute>
        <TopicDetail />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simulation/:id",
    element: (
      <ProtectedRoute>
        <LoadingSimulation />
      </ProtectedRoute>
    ),
  },
  {
    path: "/simulation/play",
    element: (
      <ProtectedRoute>
        <UnitySimulation />
      </ProtectedRoute>
    ),
  },
  {
    path: "/uploadword",
    element: (
      <ProtectedRoute>
        <UploadWord />
      </ProtectedRoute>
    ),
  },
  {
    path: "/transferQuestions",
    element: (
      <ProtectedRoute>
        <TransferQuestions />
      </ProtectedRoute>
    ),
  },
  {
    path: "/questionsUploading",
    element: (
      <ProtectedRoute>
        <UploadWord2 />
      </ProtectedRoute>
    ),
  },
  {
    path: "/editTheory/:topicId",
    element: (
      <ProtectedRoute>
        <EditTheoryPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/editChapterIntro/:chapterId",
    element: (
      <ProtectedRoute>
        <EditChapterIntroPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/addBlog/",
    element: (
      <ProtectedRoute>
        <AddBlogPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/editBlog/:id",
    element: (
      <ProtectedRoute>
        <EditBlogPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/board",
    element: (
      <ProtectedRoute>
        <Board />
      </ProtectedRoute>
    ),
  },
  {
    path: "/board/:boardId",
    element: (
      <ProtectedRoute>
        <BoardClasses />
      </ProtectedRoute>
    ),
  },
  {
    path: "/board/:boardId/:classId",
    element: (
      <ProtectedRoute>
        <BoardSubjects />
      </ProtectedRoute>
    ),
  },
  {
    path: "/board/build/subject/:subjectId",
    element: (
      <ProtectedRoute>
        <BoardChapters />
      </ProtectedRoute>
    ),
  },
]);

export default function App() {
  return (
    <Provider store={store}>
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <RouterProvider router={router} />
      </MantineProvider>
    </Provider>
  );
}
