import { Button, Stack, TextInput, useMantineTheme } from "@mantine/core";
import { useState } from "react";

interface AddTopicDialogProps {
  onSubmitClick: (topicName: string, order: string) => void;
}

export function AddTopicDialog(props: AddTopicDialogProps) {
  const [topicName, setTopicName] = useState<string>();
  const [order, setOrder] = useState<string>();
  const theme = useMantineTheme();

  return (
    <Stack>
      <TextInput
        label="Topic name"
        withAsterisk
        onChange={(e) => setTopicName(e.target.value)}
      />
      <TextInput
        label="Order"
        type="number"
        withAsterisk
        onChange={(e) => setOrder(e.target.value)}
      />
      <Button
        fullWidth
        onClick={() => props.onSubmitClick(topicName ?? "", order ?? "-1")}
        style={{ backgroundColor: theme.colors.yellow[3] }}
      >
        Submit
      </Button>
    </Stack>
  );
}
