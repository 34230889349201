import {
  ActionIcon,
  Avatar,
  Card,
  Container,
  Group,
  Paper,
  SimpleGrid,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { RoundedCard } from "../RoundedCard";

interface SimulationListProps {
  topic?: Topic | null | undefined;
  onClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
}
export function SimulationList(props: SimulationListProps) {
  const theme = useMantineTheme();

  const simulations = props.topic?.simulations.map((item) => (
    <Card
      shadow={"lg"}
      key={item._id}
      p="lg"
      style={{ backgroundColor: theme.colors.green[4] }}
    >
      <Group
        position="apart"
        style={{
          alignItems: "flex-start",
        }}
      >
        <Group
          style={{
            alignItems: "flex-start",
          }}
          onClick={() => props.onClick(item._id)}
        >
          <Avatar size={120} p={0} m={0} src={item.thumbnailImageUrl} />
          <Title
            color={"white"}
            style={{
              fontWeight: 900,
            }}
          >
            {item.name}
          </Title>
        </Group>
        <ActionIcon size="xl" onClick={() => props.onDeleteClick(item._id)}>
          <IconTrash size={30} color="red" />
        </ActionIcon>
      </Group>
    </Card>
  ));

  return (
    <Container size="xl">
      <Title>Simulations</Title>
      <SimpleGrid cols={2}>{simulations}</SimpleGrid>
    </Container>
  );
}
