import {
  Modal,
  Image,
  createStyles,
  Button,
  LoadingOverlay,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { uploadFile } from "../../features/uploadFile/uploadFileSlice";
import { AppDispatch } from "../../store/ReduxStore";

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    dropZone: {
      height: 280,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      backgroundColor: theme.colors.gray[0],

      "&[data-accept]": {
        color: theme.white,
        backgroundColor: theme.colors.blue[6],
      },

      "&[data-reject]": {
        color: theme.white,
        backgroundColor: theme.colors.red[6],
      },
    },
  };
});

export function UploadImageModal(props: {
  opened: boolean;
  onClose: () => void;
  onUploadSuccess: (url: string) => void;
}) {
  const { classes, theme } = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  function OnImageChange(file: File) {
    let reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setImagePreview(reader.result ?? "");
        setFile(file);
      }
    };
    reader.readAsDataURL(file);
  }

  function DidClickSubmit() {
    if (file == null) return;
    setLoading(true);
    dispatch(uploadFile({ file: file }))
      .unwrap()
      .then((data) => {
        setLoading(false);
        setFile(null);
        props.onUploadSuccess(data.url);
      })
      .catch((error) => {
        setLoading(false);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
  }

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="lg"
      title="Upload Image"
      overflow="inside"
      centered
    >
      <Dropzone
        onDrop={(files) => {
          OnImageChange(files[0]);
        }}
        // accept={IMAGE_MIME_TYPE}
        className={classes.dropZone}
      >
        {imagePreview && (
          <Image
            src={imagePreview}
            fit="contain"
            height={180}
            width={180}
            style={{
              backgroundColor: theme.colors.gray[0],
            }}
          />
        )}
      </Dropzone>

      <Button fullWidth size="md" onClick={DidClickSubmit}>
        Upload
      </Button>

      <LoadingOverlay visible={loading} />
    </Modal>
  );
}
