import { Button, Menu } from "@mantine/core";
import {
  IconTrash,
  IconVideo,
  IconQuestionMark,
  Icon3dRotate,
  IconDatabase,
} from "@tabler/icons";

interface TopicDetailsAddMenuProps {
  onTheoryClick: () => void;
  onVideosClick: () => void;
  onSimulationsClick: () => void;
  onQuestionsClick: () => void;
  onDeleteClick: () => void;
}

export function TopicDetailsAddMenu(props: TopicDetailsAddMenuProps) {
  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button variant="outline">Add Details</Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Details</Menu.Label>
        <Menu.Item
          icon={<IconDatabase size={14} />}
          onClick={props.onTheoryClick}
        >
          Theory
        </Menu.Item>
        <Menu.Item icon={<IconVideo size={14} />} onClick={props.onVideosClick}>
          Videos
        </Menu.Item>
        <Menu.Item
          icon={<Icon3dRotate size={14} />}
          onClick={props.onSimulationsClick}
        >
          Simulations
        </Menu.Item>
        <Menu.Item
          icon={<IconQuestionMark size={14} />}
          onClick={props.onQuestionsClick}
        >
          Questions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Label>Danger zone</Menu.Label>

        <Menu.Item
          color="red"
          icon={<IconTrash size={14} />}
          onClick={props.onDeleteClick}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
