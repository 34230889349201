import { Container, Paper, Title, Text, Group, Checkbox } from "@mantine/core";
import { QuestionCard } from "./QuestionCard";

interface QuestionsListProps {
  topic?: Topic | null | undefined;
}
export function QuestionsList(props: QuestionsListProps) {
  const questions = props.topic?.questions.map((item, index) => (
    <QuestionCard question={item} indexN={index + 1} />
  ));

  return (
    <Container size="xl">
      <Title>Questions</Title>
      {questions}
    </Container>
  );
}
