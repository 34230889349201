import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface GetSubjectByIdState {
  loadingState: LoadingState;
  value?: SimulationData[];
  error: any;
}

const initialState: GetSubjectByIdState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const getSimulationList = createAsyncThunk(
  "Simulation/Get",
  async () => {
    const response = await axios.get(
      `/api/v1/simulation/`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return null;
  }
);

export const getSimulationListSlice = createSlice({
  name: "Get Simulation List",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSimulationList.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(getSimulationList.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(getSimulationList.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default getSimulationListSlice.reducer;
