import {
  Button,
  FileInput,
  LoadingOverlay,
  Stack,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useState, useEffect } from "react";
import { AppDispatch } from "../../store/ReduxStore";
import { useDispatch } from "react-redux";
import {
  uploadFile,
  uploadModelsFiles,
} from "../../features/uploadFile/uploadFileSlice";

interface AddSimulationDataDialogProps {
  onSubmitClick: (data: any) => void;
}

export function Add3dSimulation(props: AddSimulationDataDialogProps) {
  const [name, setName] = useState<string>();
  const [modelFile, setModelFile] = useState<File | null>();
  const [thumbnailImage, setThumbnailImage] = useState<File | null>();
  const [modelUrl, setmodalUrl] = useState<string>("");
  const [thumbnailUrl, setThumbNailUrl] = useState<string>("");

  enum ValueType {
    loader,
    data,
    framework,
    wasm,
  }

  const theme = useMantineTheme();

  function DidClickSubmit() {
    const data = {
      name: name ?? "",
      modelUrl,
      thumbnailUrl,
    };
    props.onSubmitClick(data);
  }

  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (modelFile) {
      setLoading(true);
      dispatch(uploadModelsFiles({ file: modelFile }))
        .unwrap()
        .then((data) => {
          console.log(data.url);
          setLoading(false);
          setModelFile(null);
          setmodalUrl(data.url);
        })
        .catch((error) => {
          setLoading(false);
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    }
  }, [modelFile]);

  useEffect(() => {
    if (thumbnailImage) {
      setLoading(true);
      dispatch(uploadModelsFiles({ file: thumbnailImage }))
        .unwrap()
        .then((data) => {
          console.log(data.url);
          setLoading(false);
          setModelFile(null);
          setThumbNailUrl(data.url);
        })
        .catch((error) => {
          setLoading(false);
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
    }
  }, [thumbnailImage]);
  return (
    <Stack>
      <LoadingOverlay visible={isLoading} />
      <TextInput
        placeholder="3dModel Name"
        label="ModelName"
        withAsterisk
        required
        onChange={(e) => setName(e.target.value)}
      ></TextInput>
      <FileInput
        placeholder="Pick file"
        label="ModelFile"
        withAsterisk
        required
        onChange={(e) => setModelFile(e)}
      />
      <FileInput
        placeholder="Pick file"
        label="Thumbnail"
        withAsterisk
        required
        onChange={(e) => setThumbnailImage(e)}
      />
      <Button fullWidth onClick={DidClickSubmit}>
        Submit
      </Button>
    </Stack>
  );
}
