import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface DeleteClassByIdState {
  loadingState: LoadingState;
  value?: boolean;
  error: any;
}

const initialState: DeleteClassByIdState = {
  loadingState: LoadingState.IDLE,
  value: false,
  error: null,
};

export const deleteClassById = createAsyncThunk(
  "Class/Delete",
  async (id: string) => {
    const response = await axios.delete(`/api/v1/class/${id}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;
    return status == 200;
  }
);

export const deleteClassByIdSlice = createSlice({
  name: "DeleteClassByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteClassById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(deleteClassById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(deleteClassById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default deleteClassByIdSlice.reducer;
