import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface GetSimulationFilter {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: GetSimulationFilter = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const getSimulaitonFilterById = createAsyncThunk(
  "SimulationFilter/Get",
  async (params: { id: string }, dispatch) => {
    const response = await axios.get(
      `/api/v1/simulationfilters/${params.id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const getSimulaitonFilterByIdSlice = createSlice({
    name: "Get Simulation Filter By Id",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getSimulaitonFilterById.pending, (state, action) => {
          state.loadingState = LoadingState.REQUESTED;
          state.error = null;
        })
        .addCase(getSimulaitonFilterById.fulfilled, (state, action) => {
          state.value = action.payload;
          state.loadingState = LoadingState.DATA_FETECHED;
          state.error = null;
        })
        .addCase(getSimulaitonFilterById.rejected, (state, action) => {
          state.loadingState = LoadingState.ERROR;
          state.error = action.error;
        });
    },
  });
export default getSimulaitonFilterByIdSlice.reducer;
