import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Button, Modal, SimpleGrid, Text, TextInput } from "@mantine/core";
import { getAllSubjectsForClass } from "../features/boardSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import { createSubject } from "../features/subjects/createSubjectSlice";
import { AddSubjectDialog } from "../components/Dialogs/AddSubjectDialog";

export function BoardSubjects() {
  const params = useParams();
  let classId = params.classId;
  const [subjects, setSubjects] = useState([]);
  const [className, setclassName] = useState([]);

  const [createModalOpened, setModalOpened] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  function fetchList() {
    getAllSubjectsForClass(classId || "")
      .then((data: any) => {
        setclassName(data.name);
        setSubjects(data.subjects);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <>
      <Box mx={50} mt={50}>
        <Button
          onClick={() => {
            setModalOpened(true);
          }}
        >
          Add Subject
        </Button>
        <Text fz={36} fw={700}>
          {className}
        </Text>
        <Modal
          opened={createModalOpened}
          onClose={() => setModalOpened(false)}
          centered
          title="Create Subject"
        >
          <AddSubjectDialog
            onSubmitClick={(subjectName, order, filter) => {
              setModalOpened(false);
              dispatch(
                createSubject({
                  subjectName: subjectName,
                  classId: classId || "",
                  simulationFilter: filter,
                })
              )
                .unwrap()
                .then(() => {
                  fetchList();
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
        </Modal>
        <SimpleGrid cols={3} mt={50} mx={20}>
          {subjects.map((sub: any) => {
            return (
              <>
                <Button
                  onClick={() => navigate(`/board/build/subject/${sub._id}`)}
                >
                  {sub.name}
                </Button>
              </>
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
}
