import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Menu,
  Paper,
  Space,
  Stack,
  Text,
  Title,
  useMantineTheme,
  TextInput
} from "@mantine/core";
import { IconDots, IconDotsVertical, IconTrash } from "@tabler/icons";
import {useState} from 'react'
const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "black",
    background: theme.colors.orange[3],
    transition: "transform 150ms ease, box-shadow 150ms ease",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows.md,
    },
  },

  title: {
    fontFamily: `Greycliff CF ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: 32,
    marginTop: theme.spacing.xs,
    textTransform: "uppercase",
  },

  category: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    fontSize: 22,
    textTransform: "uppercase",
  },
}));

interface RoundedCard {
  image?: string;
  title: string;
  subTitle?: string;
  category?: string;
  backgroundColor?: string;
  showMenuButton: boolean;
  onDeleteClick?: () => void;
  onClick?: () => void;
  onRenameSubmit:(value:string)=>void
}

export function RoundedCard(props: RoundedCard) {
  const { classes } = useStyles();
  const [onEditName,SetonEditname]=useState(false);
  const [value, setValue] = useState(props.title);
  const theme = useMantineTheme();
  return (
    <Stack spacing={0}>
      <Menu trigger="hover" openDelay={100} closeDelay={400}>
        <Paper
          shadow="md"
          p="xl"
          radius="md"
          className={classes.card}
          style={{
            background: props.backgroundColor ?? theme.colors.yellow[3],
          }}
          m="lg"
        >
          <div
            onClick={props.onClick}
          >
            <Text className={classes.category} size="xs">
              {props.category}
            </Text>
            {
              !onEditName &&
              <Title order={3} className={classes.title}>
                {props.title}
              </Title>
            }
            <Title className={classes.category}>{props.subTitle}</Title>
          </div>
          {
              onEditName &&
              <form onSubmit={(ev)=>{
                ev.preventDefault();
                SetonEditname(false);
                props.onRenameSubmit(value);
              }}>
                <TextInput 
                  value={value}
                  onChange={(event) => setValue(event.currentTarget.value)} 
                />
                <Button mt={5} type="submit">Submit</Button>
              </form>
          }
          {props.showMenuButton && (
            <Group position="apart" style={{ width: "100%", height: "10%" }}>
              <Space></Space>
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical color={theme.black} />
                </ActionIcon>
              </Menu.Target>
            </Group>
          )}
        </Paper>

        <Menu.Dropdown>
          <Menu.Item color="red" onClick={props.onDeleteClick}>
            Delete
          </Menu.Item>
          <Menu.Item color="red" onClick={()=>{
            SetonEditname(true);
          }}>
            Rename
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Stack>
  );
}
