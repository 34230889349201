import {
  Box,
  CloseButton,
  Modal,
  MultiSelect,
  MultiSelectValueProps,
  SelectItemProps,
  Avatar,
  Group,
  Text,
  Button,
  Stack,
} from "@mantine/core";

import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getSimulationList } from "../../../features/simulation/getSimulationListSlice";
import { AppDispatch } from "../../../store/ReduxStore";

interface AddSimulationToTopicModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: { selectedSimulations: string[] }) => void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  _id: string;
  thumbnailUrl: string;
  label: string;
  description: string;
}

export const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ _id, thumbnailUrl, label, description, ...others }: ItemProps, ref) => (
    <div key={_id} ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbnailUrl} />

        <div>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export function AddSimulationToTopicModal(
  props: AddSimulationToTopicModalProps
) {
  const dispath = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState<boolean>(false);
  const [simulations, setSimulations] = useState<SimulationListData[]>([]);
  const [selectedSimulations, setSelectedSimulations] = useState<string[]>([]);

  useEffect(() => {
    fetchSimulationList();
  }, []);

  function fetchSimulationList() {
    setLoading(true);
    dispath(getSimulationList())
      .unwrap()
      .then((data: any) => {
        setSimulations([]);
        const simulationListData: SimulationListData[] = [];
        data.forEach((item: any) => {
          simulationListData.push({
            _id: item._id,
            label: item.name,
            thumbnailUrl: item.thumbnailImageUrl,
            value: item._id,
            description: item.description,
            simultionfilters: item.simultionfilters,
            isActivity: item.isActivity,
            isSimulation: item.isSimulation,
            simulationDescription: item.simulationDescription,
            simulationfeatures: item.simulationfeatures,
            simulationTags: item.simulationTags,
            simulationBackgroundColor: item.simulationBackgroundColor,
            isSimulationPremium: item.isSimulationPremium,
            videoUrl: item.videoUrl,
          });
        });
        setSimulations(simulationListData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function DidClickSubmit() {
    if (selectedSimulations == null || selectedSimulations.length <= 0) return;
    props.onSubmit({ selectedSimulations: selectedSimulations });
  }

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="lg"
      title="Add Simulation To Topic"
      overflow="inside"
      centered
    >
      <Stack>
        <MultiSelect
          placeholder="Pick all you like"
          itemComponent={SelectItem}
          data={simulations}
          searchable
          nothingFound="Nobody here"
          onChange={setSelectedSimulations}
        />

        <Button fullWidth onClick={DidClickSubmit}>
          Submit
        </Button>
      </Stack>
    </Modal>
  );
}
