import { Modal } from "@mantine/core";
import { DeleteConfirmationDialog } from "./Dialogs/DeleteConfirmationDialog";

interface DeleteConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
}

export function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      overflow="inside"
      size="lg"
      centered
      title={props.title}
    >
      <DeleteConfirmationDialog
        onConfirmClick={props.onConfirm}
        onCancelClick={props.onCancel}
      />
    </Modal>
  );
}
