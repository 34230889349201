import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface AddTheoryToTopicState {
  loadingState: LoadingState;
  value?: Topic;
  error: any;
}

const initialState: AddTheoryToTopicState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const addTheoryToTopic = createAsyncThunk(
  "subject/addVideos",
  async (params: { topicId: string; theory: string }) => {
    const response = await axios.put(
      `/api/v1/topic/${params.topicId}/addTheory`,
      {
        theory: params.theory,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      console.log(responseData);

      return responseData;
    }

    return null;
  }
);

export const addTheoryToTopicSlice = createSlice({
  name: "Topic/Add Theory",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTheoryToTopic.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(addTheoryToTopic.fulfilled, (state, action) => {
        if (action.payload != null) state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(addTheoryToTopic.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});
export async function updateRevisedTheoryToTopic(params: {
  topicId: string;
  theory: string;
}) {
  const response = await axios.put(
    `/api/v1/topic/${params.topicId}/addRevisedTheory`,
    {
      theory: params.theory,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
export default addTheoryToTopicSlice.reducer;
