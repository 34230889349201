import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";
import { QuestionType } from "../../@types/QuestionTypes";

interface CreateQuestionState {
  loadingState: LoadingState;
  value?: TopicQuestion;
  error: any;
}

const initialState: CreateQuestionState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const createQuestion = createAsyncThunk(
  "Question/create",
  async (
    params: {
      text: string;
      topicId: string;
      answers: { text: string; isCorrect: boolean }[];
      sortOrder?: number;
    },
    dispatch
  ) => {
    const response = await axios.post(
      "/api/v1/question/create",
      {
        text: params.text,
        topicId: params.topicId,
        answers: JSON.stringify(params.answers),
        sortOrder: params.sortOrder,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const createSubjectiveQuestion = createAsyncThunk(
  "Question/create",
  async (
    params: {
      text: string;
      topicId: string;
      answer:string
      sortOrder: number;
      type:string
    },
    dispatch
  ) => {
    const response = await axios.post(
      "/api/v1/subjectivequestion",
      {
        text: params.text,
        topicId: params.topicId,
        answer: params.answer,
        sortOrder: params.sortOrder,
        type:params.type
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createQuestionSlice = createSlice({
  name: "Create Question",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createQuestion.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createQuestionSlice.reducer;
