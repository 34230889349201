import {
  Button,
  Stack,
  Flex,
  ScrollArea,
  Text,
  MultiSelect,
  Group,
  LoadingOverlay,
  createStyles,
  Image,
  TextInput
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSimulationFilter } from "../../features/simulationFilter/createSimulationFilter";

import { uploadFile } from "../../features/uploadFile/uploadFileSlice";
import { AppDispatch } from "../../store/ReduxStore";
const useStyles = createStyles((theme, _params, getRef) => {
  return {
    dropZone: {
      height: 100,
      width: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: 0,
      backgroundColor: theme.colors.gray[0],

      "&[data-accept]": {
        color: theme.white,
        backgroundColor: theme.colors.blue[6],
      },

      "&[data-reject]": {
        color: theme.white,
        backgroundColor: theme.colors.red[6],
      },
    },
  };
});

interface AddSimulationFilterDialogProps{
  onClose:()=>void
}
export function AddSimulationFilterDialog(props:AddSimulationFilterDialogProps) {
  const { classes, theme } = useStyles();
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [imagePreview, setImagePreview] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [filterName,setFilterName]=useState<string>('');
  const [error,setError]=useState<boolean>(false);
  function OnImageChange(file: File) {
    let reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        setImagePreview(reader.result ?? "");
        setFile(file);
      }
    };
    reader.readAsDataURL(file);
  }

  
  function submitHandler() {
    if(filterName.length===0 || file===null){
        setError(true);
        return;
    }
    setLoading(true);
    dispatch(uploadFile({ file: file }))
      .unwrap()
      .then((data) => {
        setFile(null);
        dispatch(createSimulationFilter({name:filterName,labels:filterName,url:data.url}))
        .unwrap()
        .then((data)=>{
          setLoading(false);
          props.onClose();
        })
        .catch((error)=>{
          console.log(error)
        })
      })
      .catch((error) => {
        setLoading(false);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
  }
  return (
    <Stack>
      <LoadingOverlay visible={isLoading} />
      <ScrollArea>
        <Stack
          style={{
            border: "blue 1px solid",
            borderRadius: "10px",
          }}
          px={10}
          py={10}
          my={10}
          align="left"
        >
          <Group
            position="left"
          >
            <Text fz={18} fw={600}> Drop Image</Text>
            <Dropzone
              onDrop={(files) => {
                OnImageChange(files[0]);
              }}
              
              accept={IMAGE_MIME_TYPE}
              className={classes.dropZone}
            >
              {!imagePreview &&
                <Text fz={40}>+</Text>
              }
              {imagePreview && (
                <Image
                  src={imagePreview}
                  fit="contain"
                  height={100}
                  width={100}
                  style={{
                    backgroundColor: theme.colors.gray[0],
                  }}
                />
              )}
            </Dropzone>
          </Group>
          <TextInput
                label="Name"
                value={filterName}
                onChange={(e)=>{
                    setError(false);
                    setFilterName(e.target.value)
                }}
                withAsterisk
            />
            {
                error &&
                <Text fz={14} color="red">*Insert Image* </Text>
            }
          <Button 
            variant="filled" size="xs" ml={10}
                onClick={submitHandler}
            >
            Submit
          </Button>
        </Stack>
      </ScrollArea>
    </Stack>
  );
}
