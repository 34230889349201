import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface BlogState {
  loadingState: LoadingState;
  value?: Topic;
  error: any;
}

const initialState: BlogState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const Getblogs = createAsyncThunk(
  "blog",
  async () => {
    const response = await axios.get(
      `/api/v1/blog`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;   

      return responseData;
    }

    return null;
  }
);
export const Createblogs = createAsyncThunk(
  "blog/create",
  async (params: { data: string; title: string,imgUrl:string,isHtml:boolean }) => {
    const response = await axios.post(
      `/api/v1/blog`,
      {
        blog:params.data,
        title:params.title,
        imgUrl:params.imgUrl,
        isHtml:params.isHtml
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;   

      return responseData;
    }

    return null;
  }
);
export const EditBlog = createAsyncThunk(
  "blog/edit",
  async (params: { data: string; title: string,imgUrl:string,id:string,isHtml:boolean }) => {
    console.log(params)
    const response = await axios.put(
      `/api/v1/blog/${params.id}`,
      {
        blog:params.data,
        title:params.title,
        imgUrl:params.imgUrl,
        isHtml:params.isHtml
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;   
      return responseData;
    }

    return null;
  }
);
export const DeleteBlog = createAsyncThunk(
  "blog/edit",
  async (id: string) => {
    const response = await axios.delete(
      `/api/v1/blog/${id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);
export const FetchBlogById = createAsyncThunk(
  "blog/getById",
  async (id: string) => {
    const response = await axios.get(
      `/api/v1/blog/blogsById/${id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);

export const Blogs = createSlice({
  name: "Blog",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Getblogs.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(Getblogs.fulfilled, (state, action) => {
        if (action.payload != null) state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(Getblogs.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default Blogs.reducer;
