import { Button, Stack, TextInput, useMantineTheme } from "@mantine/core";
import { useState } from "react";

interface AddChapterDialogProps {
  chapterName?: string;
  order?: string;
  onSubmitClick: (className: string, order: string) => void;
}

export function AddChapterDialog(props: AddChapterDialogProps) {
  const [chapterName, setChapterName] = useState<string>(
    props.chapterName ?? ""
  );
  const [order, setOrder] = useState<string>(props.order ?? "-1");
  const theme = useMantineTheme();
  return (
    <Stack>
      <TextInput
        label="Chapter name"
        withAsterisk
        value={chapterName}
        onChange={(e) => setChapterName(e.target.value)}
      />
      <TextInput
        label="Order"
        type="number"
        withAsterisk
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      />
      <Button
        fullWidth
        onClick={() => props.onSubmitClick(chapterName ?? "", order ?? "-1")}
        style={{ backgroundColor: theme.colors.grape[3] }}
      >
        Submit
      </Button>
    </Stack>
  );
}
