import React, { useEffect, useState } from "react";
import { TextInput, Button, Flex } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/ReduxStore";
import { UploadObjectives } from "../../features/chapters/chapterSlice";
import { log } from "console";

interface AddObjectivesProps {
  showModal: (is: boolean) => void;
  chapter: Chapter;
}

export function AddObjectives(props: AddObjectivesProps) {
  const [fields, setFields] = useState<string[]>([]);
  const addField = () => {
    setFields([...fields, ""]);
  };
  useEffect(() => {
    setFields(props.chapter.objectives);
  }, []);
  const allFieldsFilled = fields.every((field) => field !== "");
  const dispatch = useDispatch<AppDispatch>();

  function DidClickSubmit() {
    const objectivesFiltered = fields.filter((field) => field !== "");
    console.log(objectivesFiltered);
    dispatch(
      UploadObjectives({
        chapterId: props.chapter._id,
        objectives: objectivesFiltered,
      })
    )
      .unwrap()
      .then((data) => {
        console.log("uploaded objectives");
        props.showModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleInputChange = (value: string, index: number) => {
    const newFields = [...fields];
    newFields[index] = value;
    setFields(newFields);
  };

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index} style={{ marginBottom: "8px" }}>
          <TextInput
            placeholder="Enter string"
            value={field}
            onChange={(event) =>
              handleInputChange(event.currentTarget.value, index)
            }
          />
        </div>
      ))}
      <Flex justify={"space-evenly"}>
        <Button
          onClick={addField}
          leftIcon={<IconPlus />}
          disabled={!allFieldsFilled}
        >
          Add Field
        </Button>
        <Button onClick={DidClickSubmit}>Submit</Button>
      </Flex>
    </div>
  );
}
