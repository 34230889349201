import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface CreateSimulation {
  loadingState: LoadingState;
  value?: SimulationData;
  error: any;
}

const initialState: CreateSimulation = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const create3dSimulation = createAsyncThunk(
  "Simulation/create",
  async (
    params: {
      name: string;
      modelUrl: string;
      thumbnailUrl: string;
    },
    dispatch
  ) => {
    console.log(params);
    const response = await axios.post(
      "/api/v1/threeDsimulation/create",
      {
        modelFile: params.modelUrl,
        thumbnailFile: params.thumbnailUrl,
        modelName: params.name,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const createSimulation = createAsyncThunk(
  "Simulation/create",
  async (
    params: {
      name: string;
      loaderFile: File | null | undefined;
      dataFile: File | null | undefined;
      frameworkFile: File | null | undefined;
      codeFile: File | null | undefined;
      thumbnailImage: File | null | undefined;
    },
    dispatch
  ) => {
    const loaderFile = params.loaderFile;
    const dataFile = params.dataFile;
    const frameworkFile = params.frameworkFile;
    const codeFile = params.codeFile;
    const thumbnailImageFile = params.thumbnailImage;

    const formData = new FormData();
    formData.append("name", params.name);

    if (loaderFile != null && loaderFile != undefined) {
      formData.append("loaderFIle", loaderFile);
    } else {
      console.log(`Loader File is Not valid`);
      return;
    }

    if (dataFile != null && dataFile != undefined) {
      formData.append("dataFile", dataFile);
    } else {
      console.log(`Data File is Not valid`);
      return;
    }

    if (frameworkFile != null && frameworkFile != undefined) {
      formData.append("frameworkFile", frameworkFile);
    } else {
      console.log(`Framework File is Not valid`);
      return;
    }

    if (codeFile != null && codeFile != undefined) {
      formData.append("wasmFile", codeFile);
    } else {
      console.log(`Code File is Not valid`);
      return;
    }

    if (thumbnailImageFile != null && thumbnailImageFile != undefined) {
      formData.append("thumbnailImageData", thumbnailImageFile);
    } else {
      console.log(`Thumbnail File is Not valid`);
      return;
    }

    const response = await axios.post(
      "/api/v1/simulation/create",

      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createSimulationSlice = createSlice({
  name: "Create Simulation Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createSimulation.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createSimulation.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createSimulation.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createSimulationSlice.reducer;
