import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import createChapterSlice from "../features/chapters/createChapterSlice";
import deleteChapterByIdSlice from "../features/chapters/deleteChapterSlice";
import chapterByIdSlice from "../features/chapters/getChapterByIdSlice";
import classSlice from "../features/classes/classSlice";
import createClassSlice from "../features/classes/createClassSlice";
import deleteClassByIdSlice from "../features/classes/deleteClassByIdSlice";
import getClassByIdSlice from "../features/classes/getClassSlice";
import createQuestionSlice from "../features/questions/createQuestionSlice";
import deleteSimulationById from "../features/simulation/deleteSimulationSlice";
import { updateSimulationById } from "../features/simulation/updateSimulationSlice";
import createSubjectSlice from "../features/subjects/createSubjectSlice";
import deleteSubjectByIdSlice from "../features/subjects/deleteSubjectSlice";
import getSubjectByIdSlice from "../features/subjects/getSubjectByIdSlice";
import subjectSlice from "../features/subjects/subjectSlice";
import updateSubjectByIdSlice from "../features/subjects/updateSubjectSlice";
import addTheoryToTopicSlice from "../features/topics/addTheoryToTopicSlice";
import addVideoToTopicSlice from "../features/topics/addVideoToTopicSlice";
import topicSlice from "../features/topics/topicSlice";
import uploadFileSlice from "../features/uploadFile/uploadFileSlice";
import adminAuthorizationSlice from "./adminAuthorizationSlice";

export const store = configureStore({
  reducer: {
    // class
    class: classSlice,
    createClass: createClassSlice,
    getClassById: getClassByIdSlice,
    deleteClassById: deleteClassByIdSlice,

    // subject
    subjects: subjectSlice,
    getSubjectById: getSubjectByIdSlice,
    updateSubjectbyId: updateSubjectByIdSlice,
    createSubject: createSubjectSlice,
    deleteSubject: deleteSubjectByIdSlice,

    // chapter
    createChapter: createChapterSlice,
    chapterById: chapterByIdSlice,
    deleteChapterById: deleteChapterByIdSlice,

    // topic
    topicSlice: topicSlice,
    addVideoToTopic: addVideoToTopicSlice,
    addTheoryToTopic: addTheoryToTopicSlice,

    // simulation
    deleteSimulation: deleteSimulationById,
    updateSimulationById:updateSubjectByIdSlice,
    // question
    createQuestionSlice: createQuestionSlice,

    // upload file
    uploadFileSlice: uploadFileSlice,
    adminAuthorizationSlice:adminAuthorizationSlice
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
