import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface GetClassByIdState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: GetClassByIdState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const getClassById = createAsyncThunk(
  "Subject/fetch-all",
  async (classId: string, dispatch) => {
    const response = await axios.get(`/api/v1/class/${classId}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const getClassByIdSlice = createSlice({
  name: "Get Class By ID",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClassById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(getClassById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(getClassById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default getClassByIdSlice.reducer;
