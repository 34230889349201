import {
  Button,
  Container,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  SimpleGrid,
  Title,
  useMantineTheme,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AddChapterDialog } from "../components/Dialogs/AddChapter";
import { AddSubjectDialog } from "../components/Dialogs/AddSubjectDialog";
import { DeleteConfirmationDialog } from "../components/Dialogs/DeleteConfirmationDialog";
import { RoundedCard } from "../components/RoundedCard";
import { TitleWithMoreButton } from "../components/TitleWithMoreButton";
import { fetchChapters } from "../features/chapters/chapterSlice";
import { createChapter } from "../features/chapters/createChapterSlice";
import getChapterByIdSlice from "../features/chapters/getChapterByIdSlice";
import { LoadingState } from "../features/LoadingState";
import { deleteSubjectById } from "../features/subjects/deleteSubjectSlice";
import { getSubjectById } from "../features/subjects/getSubjectByIdSlice";
import { updateSubjectById } from "../features/subjects/updateSubjectSlice";

import { AppDispatch, RootState } from "../store/ReduxStore";
import { updateChapterById } from "../features/chapters/updateChapterSlice";
import { deleteChapterById } from "../features/chapters/deleteChapterSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
import { getSimulaitonFilterById } from "../features/simulationFilter/getSimulationFilter";
const adminAuthorizationActions = authorizationModel.actions;
export function Chapters() {
  const params = useParams();
  let subjectId = params.subjectId;

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [chapters, setChapters] = useState<Chapter[]>();
  const [subject, setSubject] = useState<Subject>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const [simulaitonFilter, setSimulaitonFilter] = useState<string>("");
  const loadingState = useSelector(
    (state: RootState) => state.createChapter.loadingState
  );

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const subjectCards = chapters?.map((item) => (
    <RoundedCard
      key={item._id}
      title={item.name}
      subTitle={`Topics: ${item.topics.length}`}
      onClick={() => onChapterClick(item)}
      backgroundColor={theme.colors.grape[3]}
      showMenuButton={true}
      onRenameSubmit={(val) => {
        DidConfirmRename({
          _id: item._id,
          name: val,
          order: item.sortOrder,
        });
      }}
      onDeleteClick={() => {
        OnDeleteChapterClick(item._id);
      }}
    ></RoundedCard>
  ));
  function DidConfirmRename(data: {
    _id: string;
    name: string;
    order: number;
  }) {
    if (data._id == null) return;
    setLoadingData(true);
    dispatch(
      updateChapterById({
        chapterId: data._id,
        chapterName: data.name,
        order: data.order,
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setChapters((prev) => {
          const prev1 = prev?.map((x) => {
            if (x._id === data._id) {
              return data;
            }
            return x;
          });
          return prev1;
        });
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }
  function OnDeleteChapterClick(id: string) {
    setShowDeleteDialog(false);
    if (id == null) {
      return;
    }
    setLoadingData(true);
    dispatch(deleteChapterById(id))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setChapters((prev) => {
          const prev1 = prev?.filter((x) => x._id !== id);
          return prev1;
        });
      })
      .catch((error) => {
        dispatch(adminAuthorizationActions.setModal(true));
        setLoadingData(false);
      });
  }

  function fetchSimulationFilter(id: string) {
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(getSimulaitonFilterById({ id: id }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        console.log(data.subject);
        setSimulaitonFilter(data.subject);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }
  function onChapterClick(chapter: Chapter) {
    navigate(`/chapter/${chapter._id}`);
  }

  useEffect(() => {
    fetchSubjectData();
  }, []);

  useEffect(() => {
    if (subject) fetchSimulationFilter(subject?.simulationFilter);
  }, [subject]);

  function DidClickSubmit(chapterName: string, order: string) {
    setShowDialog(false);
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(createChapter({ chapterName: chapterName, subjectId: subjectId }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        setLoadingData(false);
        fetchData();
      });
  }

  function fetchData() {
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(fetchChapters(subjectId))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setChapters(data);
      })
      .catch((error) => setLoadingData(false));
  }

  function fetchSubjectData() {
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(getSubjectById({ subjectId: subjectId }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setSubject(data);
        fetchData();
      })
      .catch((error) => setLoadingData(false));
  }

  function OnDeleteSubjectClick() {
    setShowDeleteDialog(false);
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(deleteSubjectById(subjectId))
      .unwrap()
      .then(() => {
        setLoadingData(false);
        navigate(-1);
      })
      .catch((error) => {
        setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true));
      });
  }

  function DidUpdateSubject(
    subjectName: string,
    order: string,
    simulationFilter: string
  ) {
    setShowUpdateDialog(false);
    if (subjectId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(
      updateSubjectById({
        id: subjectId,
        name: subjectName,
        order: Number(order),
        simulationFilter: simulationFilter,
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchSubjectData();
      })
      .catch((error) => setLoadingData(false));
  }

  return (
    <Container size="xl">
      <Group position="apart" p="xl">
        <Title>{subject?.name}</Title>
        <Group>
          <Text>Current Filter:{simulaitonFilter}</Text>
          <Button
            variant="outline"
            color="green"
            onClick={() => setShowDialog(true)}
          >
            Add Chapter
          </Button>
          <Button
            variant="outline"
            color="blue"
            onClick={() => setShowUpdateDialog(true)}
          >
            Update Subject
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => setShowDeleteDialog(true)}
          >
            Delete Subject
          </Button>
        </Group>
      </Group>
      {/* All Chapters */}
      <Title p="xl">All Chapters</Title>
      <SimpleGrid cols={3}>{subjectCards}</SimpleGrid>
      <Modal
        centered
        opened={showDialog}
        onClose={() => setShowDialog(false)}
        title="Add Chapter"
      >
        <AddChapterDialog
          onSubmitClick={(chapterName, order) =>
            DidClickSubmit(chapterName, order)
          }
        />
      </Modal>
      <Modal
        centered
        opened={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title={`Are you sure you want to delete ${subject?.name}?`}
        style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
      >
        <DeleteConfirmationDialog
          onConfirmClick={OnDeleteSubjectClick}
          onCancelClick={() => {
            setShowDeleteDialog(false);
          }}
        />
      </Modal>
      <Modal
        centered
        opened={showUpdateDialog}
        onClose={() => setShowUpdateDialog(false)}
      >
        <AddSubjectDialog
          onSubmitClick={DidUpdateSubject}
          subjectName={subject?.name}
          order={String(subject?.sortOrder)}
        />
      </Modal>
      <LoadingOverlay visible={loadingData}></LoadingOverlay>
    </Container>
  );
}
