import { Button, Container, Group, Space, Stack, Title } from "@mantine/core";

interface DeleteConfirmationDialogProps {
  onConfirmClick: () => void;
  onCancelClick: () => void;
}

export function DeleteConfirmationDialog(props: DeleteConfirmationDialogProps) {
  return (
    <Container>
      <Stack>
        <Group position="center">
          <Button
            color="blue"
            variant="filled"
            onClick={props.onCancelClick}
            fullWidth
          >
            Cancel
          </Button>
          <Button
            color="red"
            variant="outline"
            onClick={props.onConfirmClick}
            fullWidth
          >
            Confirm
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}
