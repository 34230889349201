import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface DeleteSubjectState {
  loadingState: LoadingState;
  value?: boolean;
  error: any;
}

const initialState: DeleteSubjectState = {
  loadingState: LoadingState.IDLE,
  value: false,
  error: null,
};

export const deleteSubjectById = createAsyncThunk(
  "subject/fetchChapter",
  async (id: string) => {
    const response = await axios.delete(
      `/api/v1/subject/${id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);

export const deleteSubjectByIdSlice = createSlice({
  name: "DeleteSubjectByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteSubjectById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(deleteSubjectById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(deleteSubjectById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default deleteSubjectByIdSlice.reducer;
