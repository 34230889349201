import {
  Modal,
  Flex,
  Button,
  Text,
  Stack,
  LoadingOverlay,
  TextInput,
  Image,
  Group,
} from "@mantine/core";
import { useEffect, useState, Fragment } from "react";
import {
  Createblogs,
  DeleteBlog,
  EditBlog,
  Getblogs,
} from "../../../features/blog/blogSlice";
import { useDispatch } from "react-redux";
import { AddNewBlogModal } from "./AddNewBlogModal";
import { AppDispatch } from "../../../store/ReduxStore";
import { EditBlogModal } from "./EditBlogModal";
import { DeleteConfirmationDialog } from "../../Dialogs/DeleteConfirmationDialog";
import { authorizationModel } from "../../../store/adminAuthorizationSlice";
import { useNavigate } from "react-router-dom";
const adminAuthorizationActions=authorizationModel.actions;

export function AllBlogsModal() {
  const dispatch = useDispatch<AppDispatch>();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [editBlog, setEditBlog] = useState<Blog | null>(null);
  const [isLoading, setisLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<string>('');
  const navigate=useNavigate();

  function fetchAllBlogs() {
    dispatch(Getblogs())
      .unwrap()
      .then((data: any) => {
        setBlogs(data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  useEffect(() => {
    fetchAllBlogs();
  }, []);

  // async function formHandler(data: {
  //   title: string;
  //   imgUrl: string;
  //   data: string;
  // }) {
  //   setisLoading(true);
  //   dispatch(
  //     Createblogs({ imgUrl: data.imgUrl, title: data.title, data: data.data })
  //   )
  //     .unwrap()
  //     .then((data: any) => {
  //       setisLoading(false);
  //       setBlogs((prev) => {
  //         return [...prev, data];
  //       });
  //       console.log(data);
  //     })
  //     .catch((error: any) => {
  //       dispatch(adminAuthorizationActions.setModal(true))
  //       setisLoading(false);
  //       console.log(error);
  //     });
  // }
  // async function editBlogHandler(data: {
  //   title: string;
  //   imgUrl: string;
  //   data: string;
  // }) {
  //   setisLoading(true);
  //   if (editBlog)
  //     dispatch(
  //       EditBlog({
  //         imgUrl: data.imgUrl,
  //         title: data.title,
  //         data: data.data,
  //         id: editBlog?._id,
  //       })
  //     )
  //       .unwrap()
  //       .then((data: any) => {
  //         setisLoading(false);
  //         console.log(data)
  //         setBlogs((prev) => {
  //           const prev1=prev.map((x)=>{
  //             if(x._id===data.id){
  //               return data
  //             }
  //             return x;
  //           })
  //           return prev1;
  //         });
  //         setEditBlog(null)
  //         console.log(data);
  //       })
  //       .catch((error: any) => {
  //       dispatch(adminAuthorizationActions.setModal(true))
  //         setisLoading(false);
  //         setEditBlog(null)
  //         console.log(error);
  //       });
  // }
  async function deleteBlogHandler() {
    setisLoading(true);
      dispatch(
        DeleteBlog(showDeleteDialog)
      )
        .unwrap()
        .then((data) => {
          setisLoading(false);
          console.log(data);
          setBlogs((prev) => {
            const prev1=prev.filter(x=>x._id!==data._id)
            return prev1;
          });
          setShowDeleteDialog('')
        })
        .catch((error: any) => {
          setisLoading(false);
          setShowDeleteDialog('')
          console.log(error);
          dispatch(adminAuthorizationActions.setModal(true))
        });
  }

  return (
    <Fragment>
      <Stack>
        <Button
          onClick={() => {
            navigate('/addBlog')
          }}
        >
          Add Blog
        </Button>
        {blogs.map((x) => {
          return (
            <Group
              my={5}
              style={{
                border: "blue solid 1px",
              }}
              position="apart"
              px={5}
            >
              <Text>{x.title}</Text>
              <Flex direction="column">
                <Button
                  my={2}
                  onClick={() => {
                    navigate(`/editBlog/${x._id}`)
                    setEditBlog(x);
                  }}
                >
                  Edit
                </Button>
                <Button 
                  color="red" 
                  my={2}
                  onClick={()=>{
                    setShowDeleteDialog(x._id);
                  }}
                >
                  Delete
                </Button>
              </Flex>
            </Group>
          );
        })}
      </Stack>
      <LoadingOverlay visible={isLoading} />
      <Modal
        centered
        opened={showDeleteDialog!==''}
        onClose={() => setShowDeleteDialog('')}
        title={`Are you sure you want to delete the blog?`}
        style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
      >
        <DeleteConfirmationDialog
          onConfirmClick={deleteBlogHandler}
          onCancelClick={() => {
            setShowDeleteDialog('');
          }}
        />
      </Modal>
    </Fragment>
  );
}
