import { Modal, ActionIcon, TextInput } from "@mantine/core";


export function ShowImageUrlModal(props: {
  opened: boolean;
  onClose: () => void;
  urlValue: string | null;
}) {
  const copyToClipboard = async (url: string | null) => {
    if (url == null) return;
    await navigator.clipboard.writeText(url);
    props.onClose();
  };

  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="xl"
      title="Image Uploaded"
      overflow="inside"
      centered
    >
      <TextInput size="xl" value={props.urlValue ?? "Some Error Occured"} />
    </Modal>
  );
}
