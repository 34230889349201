import { Fragment, useEffect, useState } from "react";
import { TopicTheoryModal } from "../components/TopicDetails/Modals/TopicTheoryModal";
import { Flex, LoadingOverlay } from "@mantine/core";
import { fetchTopicDetails } from "../features/topics/topicSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import { useNavigate, useParams } from "react-router-dom";
import { addTheoryToTopic } from "../features/topics/addTheoryToTopicSlice";
import { fetchChapterById } from "../features/chapters/getChapterByIdSlice";
import { updateChapterIntoById } from "../features/chapters/updateChapterSlice";
import { IconChevronsDownLeft } from "@tabler/icons";

export function EditChapterIntroPage()
{
  const [chapter, setChapter] = useState<Chapter>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [chapterId,setChapterId]=useState<string>('')
  const dispatch = useDispatch<AppDispatch>();
  const navigate=useNavigate();
  const params = useParams();
  useEffect(()=>{
    if(params.chapterId)
        setChapterId(params.chapterId)
  },[params])
  async function fetchData() {
    if (chapterId == null || chapterId.length <= 0) {
      return;
    }
    setLoadingData(true);
    await dispatch(fetchChapterById(chapterId))
      .unwrap()
      .then((data) => {
        console.log(data)
        setLoadingData(false);
        if (data != null) setChapter(data);
      })
      .catch((error) => {
        console.log(error)
        setLoadingData(false);
      });
  }
  async function DidSubmitTheory(theory: string) {
    if (chapterId == null) {
      return;
    }
    setLoadingData(true);
    
    await dispatch(updateChapterIntoById({ chapterId:chapterId,introduction:theory }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        console.log(data)
        navigate(`/chapter/${chapterId}`)
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(chapterId)
    fetchData();
  }, [chapterId]);
    return(
        <Flex
        w={'100%'}
        h={'100vh'}
        >
        <TopicTheoryModal
            text={chapter?.introduction}
            onSubmit={DidSubmitTheory}
        />
        <LoadingOverlay
            visible={loadingData}
        />
        </Flex>
    )
}