import {
  Button,
  FileInput,
  Stack,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";

interface AddSimulationDataDialogProps {
  onSubmitClick: (data: {
    name: string;
    loaderFile?: File | null | undefined;
    dataFile?: File | null | undefined;
    frameworkFile?: File | null | undefined;
    codeFile?: File | null | undefined;
    thumbnailImage?: File | null | undefined;
  }) => void;
}

export function AddSimulationDataDialog(props: AddSimulationDataDialogProps) {
  const [name, setName] = useState<string>();
  const [loaderFile, setLoaderFile] = useState<File | null>();
  const [dataFile, setDataFile] = useState<File | null>();
  const [framweorkFile, setFrameworkFile] = useState<File | null>();
  const [wasmFile, setWasmFile] = useState<File | null>();
  const [thumbnailImage, setThumbnailImage] = useState<File | null>();
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>();
  
  enum ValueType {
    loader,
    data,
    framework,
    wasm,
  }

  const theme = useMantineTheme();

  function DidClickSubmit() {
    const data = {
      name: name ?? "",
      loaderFile: loaderFile,
      dataFile: dataFile,
      frameworkFile: framweorkFile,
      codeFile: wasmFile,
      thumbnailImage: thumbnailImage,
    };

    props.onSubmitClick(data);
  }

  return (
    <Stack>
      <TextInput
        placeholder="Simulation Name"
        label="Name"
        withAsterisk
        required
        onChange={(e) => setName(e.target.value)}
      ></TextInput>
      <FileInput
        placeholder="Pick file"
        label="Data"
        withAsterisk
        required
        onChange={(e) => setDataFile(e)}
      />
      <FileInput
        placeholder="Pick file"
        label="Framework"
        withAsterisk
        required
        onChange={(e) => setFrameworkFile(e)}
      />
      <FileInput
        placeholder="Pick file"
        label="Loader"
        withAsterisk
        required
        onChange={(e) => setLoaderFile(e)}
      />
      <FileInput
        placeholder="Pick file"
        label="Wasm"
        withAsterisk
        required
        onChange={(e) => setWasmFile(e)}
      />
      <FileInput
        placeholder="Pick file"
        label="Thumbnail"
        withAsterisk
        required
        onChange={(e) => setThumbnailImage(e)}
      />

      <Button
        fullWidth
        onClick={DidClickSubmit}
        disabled={false}
        style={{ backgroundColor: theme.colors.green[3] }}
      >
        Submit
      </Button>
    </Stack>
  );
}
