import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface CreateSubjectState {
  loadingState: LoadingState;
  value: string | null;
  error: any;
}

const initialState: CreateSubjectState = {
  loadingState: LoadingState.IDLE,
  value: null,
  error: null,
};

export const uploadFile = createAsyncThunk(
  "Upload File",
  async (params: { file: File }) => {
    const formData = new FormData();
    formData.append("file", params.file);

    const response = await axios.post("/api/v1/fileupload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const uploadModelsFiles = createAsyncThunk(
  "Upload File",
  async (params: { file: File }) => {
    const formData = new FormData();
    formData.append("file", params.file);

    const response = await axios.post(
      "/api/v1/fileupload/3dSimualtionFiles",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const uploadWord = createAsyncThunk(
  "Upload Word",
  async (params: { files: File[]; file: File; subjectId: string }) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < params.files.length; i++) {
        formData.append("files", params.files[i]);
      }
      const file2 = new File([params.file], "main.html", {
        type: params.file.type,
      });

      formData.append("files", file2);
      const response = await axios.post(
        `/api/v1/wordUpload/${params.subjectId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
          },
        }
      );
      return response;
    } catch (e: any) {
      console.log(e.response.data);
      return e.response;
    }
  }
);
export const uploadMcqQuestions = createAsyncThunk(
  "Upload Word",
  async (params: { files: File[]; file: File; subjectId: string }) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < params.files.length; i++) {
        formData.append("files", params.files[i]);
      }
      const file2 = new File([params.file], "main.html", {
        type: params.file.type,
      });

      formData.append("files", file2);
      const response = await axios.post(
        `/api/v1/wordUpload/generateQuestions`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
          },
        }
      );
      return response;
    } catch (e: any) {
      console.log(e.response.data);
      return e.response;
    }
  }
);

export const uploadFileSlice = createSlice({
  name: "Upload File",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default uploadFileSlice.reducer;
