import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getBoardAndClasses } from "../features/boardSlice";
import { Box, Button, SimpleGrid, Text } from "@mantine/core";

export function BoardClasses() {
  const params = useParams();
  let boardId = params.boardId;
  const [boardName, setBoardName] = useState("");
  const [classes, setClasses] = useState([]);
  const navigate = useNavigate();
  function fetchList() {
    getBoardAndClasses(boardId || "")
      .then((data: any) => {
        setBoardName(data.name);
        setClasses(data.classes);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchList();
  }, []);
  return (
    <>
      <Box mx={50} mt={50}>
        <Text fz={36} fw={700}>
          {boardName}
        </Text>
        <SimpleGrid cols={3} mt={50}>
          {classes.map((class1: any) => {
            return (
              <>
                <Button
                  onClick={() => navigate(`/board/${boardId}/${class1._id}`)}
                >
                  {class1.name}
                </Button>
              </>
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
}
