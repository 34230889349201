import { Button, Container, Paper, Title } from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { RoundedCard } from "../RoundedCard";

interface VideosListProps {
  topic?: Topic | null | undefined;
}
export function VideosList(props: VideosListProps) {
  const videos = props.topic?.videos?.map((item) => (
    <Paper p="xs">
      <iframe width="100%" height="70%" src={item}></iframe>
      <Button fullWidth variant="filled" color="red" leftIcon={<IconTrash />}>
        Delete
      </Button>
    </Paper>
  ));

  return (
    <Container size="xl">
      <Title>Videos</Title>
      {videos}
    </Container>
  );
}
