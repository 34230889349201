import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface DeleteSimulationByIdState {
  loadingState: LoadingState;
  value?: boolean;
  error: any;
}

const initialState: DeleteSimulationByIdState = {
  loadingState: LoadingState.IDLE,
  value: false,
  error: null,
};

export const deleteSimulationById = createAsyncThunk(
  "Simulation/Delete",
  async (id: string) => {
    const response = await axios.delete(
      `/api/v1/simulation/${id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;
    return status == 200;
  }
);

export const deleteSimulationByIdSlice = createSlice({
  name: "DeleteSimulationByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteSimulationById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(deleteSimulationById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(deleteSimulationById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default deleteSimulationByIdSlice.reducer;
