import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface ClassListState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: ClassListState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const fetchClassList = createAsyncThunk(
  "classes/fetch-all",
  async (dispatch) => {
    const response = await axios.get("/api/v1/class/", {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const fetchBoardList = createAsyncThunk(
  "classes/fetch-all",
  async (dispatch) => {
    const response = await axios.get("/api/v1/board/", {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createClass = createAsyncThunk(
  "classes/create",
  async (dispatch) => {
    const response = await axios.get("/api/v1/class/", {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const classSlice = createSlice({
  name: "ClassSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassList.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(fetchClassList.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(fetchClassList.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default classSlice.reducer;
