import { Button, Stack, TextInput, useMantineTheme,Select, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/ReduxStore";
import { getSimulationFilters } from "../../features/simulation/getSimulationSlice";

interface AddSubjectDialogProps {
  subjectName?: string;
  order?: string;
  buttonTitle?: string;
  onSubmitClick: (subjectName: string, order: string,filter:string) => void;
}
export function AddSubjectDialog(props: AddSubjectDialogProps) {
  const [subjectName, setSubjectName] = useState<string>(
    props.subjectName ?? ""
  );
  const [order, setOrder] = useState<string>(props.order ?? "");
  const [value,setValue]=useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [simulaitonFilter, setSimualtionFilters] = useState<
    SimulationFilterData[]
  >([]);
  const dispath = useDispatch<AppDispatch>();
  function fetchSimulationfilters() {
    setLoading(true);
    dispath(getSimulationFilters())
      .unwrap()
      .then((data: SimulationFiltersData[]) => {
        setLoading(false);
        const simulationListData: SimulationFilterData[] = [];
        data.forEach((item) => {
          if (item.subject !== "All Simulations") {
            simulationListData.push({
              value: item._id,
              label: item.subject,
            });
          }
        });
        setSimualtionFilters(simulationListData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  useEffect(() => {
    fetchSimulationfilters();
  }, []);
  const theme = useMantineTheme();
  return (
    <Stack>
      <LoadingOverlay visible={loading}/>
      <TextInput
        label="Subject name"
        withAsterisk
        value={subjectName}
        onChange={(e) => setSubjectName(e.target.value)}
      />
      <TextInput
        label="Order"
        type="number"
        withAsterisk
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      />
      <Select
        label="Choose Simulation Filter"
        placeholder="Pick one"
        data={simulaitonFilter}
        value={value}
        onChange={setValue}
      />
      <Button
        fullWidth
        onClick={() => props.onSubmitClick(subjectName ?? "", order ?? "-1",value??"")}
        style={{ backgroundColor: theme.colors.teal[3] }}
      >
        {props.buttonTitle ?? "Submit"}
      </Button>
    </Stack>
  );
}
