import {
  Button,
  FileInput,
  Stack,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { uploadFile } from "../../features/uploadFile/uploadFileSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/ReduxStore";
import { UploadLessonPlan } from "../../features/chapters/chapterSlice";

interface AddLessonPlanProps {
  chapterId: string;
  showModal : (is : boolean) => void; 
}

export function AddLessonPlan(props: AddLessonPlanProps) {
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  function LessonPlanUpload(url : string , fileName : string) {
    if (props.chapterId == null) {
      return;
    }
    dispatch(
      UploadLessonPlan({
        chapterId: props.chapterId,
        url: url,
        fileName: fileName,
      })
    )
      .unwrap()
      .then((data) => {console.log("uploaded lesson plan");
      }) 
      .catch((error) => {
        console.log(error);
      });
  }
  function handleFileChange(file: File | null) {
    if (file) {
      setFile(file);
      
    }
  }

  function DidClickSubmit() {
    if (file == null) return;
    dispatch(uploadFile({ file: file }))
      .unwrap()
      .then((data) => {
        setFile(null);
        LessonPlanUpload(data.url,file.name);
        props.showModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <FileInput
        label="Upload Lesson plan"
        placeholder="Upload files"
        onChange={(file) => handleFileChange(file)}
      />
      <Button mt={20} onClick={DidClickSubmit}>
        Submit
      </Button>
    </>
  );
}
