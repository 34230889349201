import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface UpdateSubjectByIdState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: UpdateSubjectByIdState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const updateSubjectById = createAsyncThunk(
  "classes/Updte",
  async (params: { id: string; name: string; order: number,simulationFilter:string }) => {
    const response = await axios.put(
      `/api/v1/subject/${params.id}`,
      {
        name: params.name,
        sortOrder: params.order,
        simulationFilter:params.simulationFilter
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const updateSubjectByIdSlice = createSlice({
  name: "Update Subject Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSubjectById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(updateSubjectById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(updateSubjectById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default updateSubjectByIdSlice.reducer;
