import { Container, LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getSimulationById } from "../../features/simulation/getSimulationSlice";
import { AppDispatch } from "../../store/ReduxStore";
import {
  LocalStorageKey,
  SaveValueToLocalStorage,
} from "../../utilities/LocalstorageUtility";

export function SimulationPlay() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const id = params.id;
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [simulation, setSimulation] = useState<SimulationData>();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    if (id == null) {
      return;
    }
    setLoadingData(true);
    dispatch(getSimulationById({ id: id }))
      .unwrap()
      .then((data) => {
        setSimulation(data);
        setLoadingData(false);
        SaveValueToLocalStorage(
          LocalStorageKey.SimulationData,
          JSON.stringify(data)
        );
        navigate("/simulation/play");
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  return (
    <Container>
      <LoadingOverlay visible={loadingData}></LoadingOverlay>
    </Container>
  );
}
