import { Modal } from "@mantine/core";
import { AddVideoURLDialog } from "../../Dialogs/AddVideoUrlDialog";

interface AddVideoModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (url: string) => void;
}

export function AddVideoModal(props: AddVideoModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="xl"
      title="Add Video"
      overflow="inside"
      centered
    >
      <AddVideoURLDialog onSubmitClick={props.onSubmit} />
    </Modal>
  );
}
