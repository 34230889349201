import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface CreateTopic {
  loadingState: LoadingState;
  value?: Topic;
  error: any;
}

const initialState: CreateTopic = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const createTopic = createAsyncThunk(
  "Topic/create",
  async (params: { chapterId: string; name: string; order: number }) => {
    const response = await axios.post(
      "/api/v1/topic/create",
      {
        name: params.name,
        chapterId: params.chapterId,
        sortOrder: params.order,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createTopicSlice = createSlice({
  name: "Create Topic Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTopic.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createTopic.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createTopic.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createTopicSlice.reducer;
