import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface DeleteChapterState {
  loadingState: LoadingState;
  value?: boolean;
  error: any;
}

const initialState: DeleteChapterState = {
  loadingState: LoadingState.IDLE,
  value: false,
  error: null,
};

export const deleteChapterById = createAsyncThunk(
  "subject/deleteChapter",
  async (chapterId: string) => {
    const response = await axios.delete(`/api/v1/chapter/${chapterId}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;
    return status == 200;
  }
);

export const deleteChaptersByIds = createAsyncThunk(
  "subject/deleteChapter",
  async (chapterIds: string[]) => {
    const response = await axios.post(`/api/v1/chapter/`,
    {
      chapterIds:chapterIds
    },
    {headers: {
      authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
    },}
  );

    const status = response.status;
    return status == 200;
  }
);

export const deleteChapterByIdSlice = createSlice({
  name: "DeleteChapterByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteChapterById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(deleteChapterById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(deleteChapterById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default deleteChapterByIdSlice.reducer;
