import {
  Button,
  Container,
  Divider,
  FileInput,
  Flex,
  LoadingOverlay,
  Radio,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import {
  uploadMcqQuestions,
  uploadWord,
} from "../features/uploadFile/uploadFileSlice";
import { fetchClassList } from "../features/classes/classSlice";
import { fetchSubjectList } from "../features/subjects/subjectSlice";
import { deleteChaptersByIds } from "../features/chapters/deleteChapterSlice";
import { getAllBoards } from "../features/boardSlice";
import {
  UploadQuestions,
  fetchChapters,
} from "../features/chapters/chapterSlice";
import { IconTrash } from "@tabler/icons";

export function UploadWord2() {
  const dispatch = useDispatch<AppDispatch>();
  const [files, setFiles] = useState<File[]>([]);
  const [mainFile, setMainFile] = useState<File | null>(null);
  const [classModel, setClassModel] = useState<
    {
      label: string;
      value: string;
      boardId: string;
    }[]
  >([]);
  const [fiteredclassModel, setFiteredClassModel] = useState<
    {
      label: string;
      value: string;
      boardId: string;
    }[]
  >([]);
  const [selectedClass, setSelectedClass] = useState<string | null>("");
  const [selectedSubject, setSelectedSubject] = useState<string | null>("");
  const [selectedChapter, setSelectedChapter] = useState<string | null>("");
  const [subjects, setSubjects] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [chaptersIds, setChapterIds] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const [allTopics, setAllTopics] = useState<
    {
      chapterName: string;
      topics: string[];
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [matchthefollowing, setData] = useState<any[]>([]);
  const [questions, setData1] = useState<any[]>([]);
  const [assertiveReasoning, setData2] = useState<any[]>([]);
  const [intergerbased, setinterbased] = useState<any[]>([]);
  const [multipleCorrect, setMultipleCorrect] = useState<any[]>([]);
  const [linkedComprehension, setLinkedComprehension] = useState<any[]>([]);
  const [selectedBoard, setSelectedBoard] = useState<string | null>("");
  const [boards, setBoards] = useState<any>([]);
  const [chapters, setChapters] = useState<any>([]);

  useEffect(() => {
    if (selectedSubject) {
      dispatch(fetchChapters(selectedSubject))
        .unwrap()
        .then((x) => {
          setIsLoading(false);
          console.log(x);
          setChapters(x.map((y: any) => ({ label: y.name, value: y._id })));
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  }, [selectedSubject]);
  useEffect(() => {
    if (selectedBoard)
      setFiteredClassModel(
        classModel?.filter((x) => x.boardId === selectedBoard)
      );
  }, [selectedBoard]);
  function fetchAllBoards() {
    getAllBoards()
      .then((data: any) => {
        if (data) {
          setBoards(
            data.map((x: any) => {
              return {
                label: x.name,
                value: x._id,
              };
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchAllBoards();
  }, []);

  const assertivequestions = [
    "Statement 1 is True, Statement 2 is True; Statement 2 is correct explanation for Statement 1",
    "Statement 1 is True, Statement 2 is True; Statement 2 is not correct explanation for Statement 1",
    "Statement 1 is True, Statement 2 is False",
    "Statement 1 is False, Statement 2 is True",
  ];
  async function uploadTopics() {
    setIsLoading(true);

    if (files && mainFile && selectedSubject)
      dispatch(
        uploadMcqQuestions({
          files: files,
          file: mainFile,
          subjectId: selectedSubject,
        })
      )
        .unwrap()
        .then((x) => {
          if (x.status !== 200) {
            if (x.data) {
              console.log(x.data);
              // setData1(x.data.questions);
            } else if (x.status === 403 || x.status === 401) {
              setError("Unauthorized User");
            } else {
              setError("Some Error Occured");
            }
          } else {
            console.log(x.data);
            // setData(x.data.matrixmatchTypeQ);
            setData1(x.data.questions);
            // setMultipleCorrect(x.data.multiplequestions);
            // setinterbased(x.data.intergerBasedQ);
            // setLinkedComprehension(x.data.LinkedComprehensionBasedQ);
            // setData2(
            //   x.data.assertionReasoningQ.map((y: any) => {
            //     return {
            //       text: y.statements[0] + y.statements[1],
            //       options: assertivequestions.map((z, i) => {
            //         return {
            //           text: z,
            //           isCorrect: i === y.answer,
            //         };
            //       }),
            //       solution: y.solution,
            //     };
            //   })
            // );
          }

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          setData1(e.response.data.questions);
        });
  }

  async function deleteChaptersHandler() {
    setIsLoading(true);
    console.log(chaptersIds);
    const chapterids = chaptersIds.map((x) => {
      return x.id;
    });
    console.log(chapterids);
    dispatch(deleteChaptersByIds(chapterids))
      .unwrap()
      .then((x) => {
        setIsLoading(false);
        setChapterIds([]);
        setAllTopics([]);
        console.log(x);
      })
      .catch((e) => {
        setIsLoading(false);
        setError("Error in deleting Chapters");
        console.log(e);
      });
  }
  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchClassList())
      .unwrap()
      .then((x) => {
        setIsLoading(false);

        const temp: {
          label: string;
          value: string;
          boardId: string;
        }[] = [];
        x.map((y: any) => {
          let temp1 = {
            label: y.name,
            value: y._id,
            boardId: y.boardId,
          };
          temp.push(temp1);
        });
        setClassModel(temp);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (selectedClass !== "" && selectedClass) {
      setIsLoading(true);

      dispatch(fetchSubjectList(selectedClass))
        .unwrap()
        .then((x) => {
          setIsLoading(false);
          const temp: {
            label: string;
            value: string;
          }[] = [];
          x.map((y: any) => {
            let temp1 = {
              label: y.name,
              value: y._id,
            };
            temp.push(temp1);
          });
          setSubjects(temp);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e);
        });
    }
  }, [selectedClass]);

  function uploadQuestions() {
    setIsLoading(true);
    dispatch(
      UploadQuestions({
        chapterId: selectedChapter ?? "",
        assertiveQuestions: assertiveReasoning,
        mcqQuestions: questions,
        matchthefollowingQuestions: matchthefollowing,
        linkedComprehensionBasedQuestions: linkedComprehension,
        multipleCorrectAnswerquestions: multipleCorrect,
        intergerBasedQuestions: intergerbased,
      })
    )
      .unwrap()
      .then((x) => {
        alert("Uploaded SuccessFully");
        setIsLoading(false);
        console.log(x);
      })
      .catch((e) => {
        alert("Error in Uploading");
        setIsLoading(false);
        console.log(e);
      });
  }

  return (
    <Container mt={100}>
      <Stack>
        <LoadingOverlay visible={isLoading} />
        <Select
          value={selectedBoard}
          onChange={setSelectedBoard}
          data={boards}
          placeholder="Select Board"
        />
        <Select
          value={selectedClass}
          onChange={setSelectedClass}
          data={fiteredclassModel}
          placeholder="Select Class"
        />
        <Select
          value={selectedSubject}
          onChange={setSelectedSubject}
          data={subjects}
          placeholder="Select Subject"
        />
        <Select
          value={selectedChapter}
          onChange={setSelectedChapter}
          data={chapters}
          placeholder="Select Chapter"
        />
        <FileInput
          value={mainFile}
          onChange={setMainFile}
          placeholder="Upload Main File"
        />
        <FileInput
          value={files}
          onChange={setFiles}
          placeholder="Upload Folder Files"
          multiple
        />
        <Button
          onClick={() => {
            uploadTopics();
          }}
        >
          Uploadx
        </Button>
        <Button
          onClick={() => {
            uploadQuestions();
          }}
        >
          Upload Questions
        </Button>
        {error !== "" && (
          <Text color="red" fz={"20px"}>
            {error}
          </Text>
        )}
        <Text fz={25} fw={700}>
          MCQ Questions
        </Text>

        {questions.map((x, i) => {
          return (
            <Flex>
              <Stack>
                <Flex>
                  <Text>{i + 1}.</Text>
                  <Stack>
                    <div dangerouslySetInnerHTML={{ __html: x.text }}></div>
                    {x.options.map((y: any) => {
                      return (
                        <Flex>
                          <Radio checked={y.isCorrect}></Radio>
                          <div
                            dangerouslySetInnerHTML={{ __html: y.text }}
                          ></div>
                        </Flex>
                      );
                    })}
                  </Stack>
                </Flex>
                <Text fz={20} fw={600}>
                  Solution
                </Text>
                <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>
                <Divider />
              </Stack>
              <IconTrash
                onClick={() => {
                  setData1((prev) => {
                    return prev.filter((y, index) => index !== i);
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "red",
                  marginLeft: "10px",
                }}
                size={25}
              />
            </Flex>
          );
        })}

        <Text fz={25} fw={700}>
          Multiple Correct
        </Text>

        {multipleCorrect.map((x, i) => {
          return (
            <div>
              <Flex>
                <Text>{i + 1}.</Text>
                <Stack>
                  <div dangerouslySetInnerHTML={{ __html: x.text }}></div>

                  {x.options.map((y: any) => {
                    return (
                      <Flex>
                        <Radio checked={y.isCorrect}></Radio>
                        <div dangerouslySetInnerHTML={{ __html: y.text }}></div>
                        ;
                      </Flex>
                    );
                  })}
                </Stack>
              </Flex>
              <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>

              <Divider />
            </div>
          );
        })}
        <Text fz={25} fw={700}>
          Assertive Reasoning
        </Text>
        {assertiveReasoning.map((x, i) => {
          return (
            <div>
              <Flex>
                <Text>{i + 1}.</Text>
                <Stack>
                  <div dangerouslySetInnerHTML={{ __html: x.text }}></div>
                  {x.options.map((y: any) => {
                    return (
                      <Flex>
                        <Radio checked={y.isCorrect}></Radio>
                        <div dangerouslySetInnerHTML={{ __html: y.text }}></div>
                        ;
                      </Flex>
                    );
                  })}
                </Stack>
              </Flex>
              <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>
              <Divider />
            </div>
          );
        })}

        <Text fz={25} fw={700}>
          Match the following
        </Text>

        {matchthefollowing.map((x, i) => {
          return (
            <div>
              <Flex>
                <Text>{i + 1}.</Text>
                <Stack>
                  <div dangerouslySetInnerHTML={{ __html: x.text }}></div>

                  {x.options.map((y: any) => {
                    return (
                      <Flex>
                        <Radio checked={y.isCorrect}></Radio>
                        <div dangerouslySetInnerHTML={{ __html: y.text }}></div>
                        ;
                      </Flex>
                    );
                  })}
                </Stack>
              </Flex>
              <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>
              <Divider />
            </div>
          );
        })}

        <Text fz={25} fw={700}>
          Match the following
        </Text>
        {linkedComprehension.map((x, i) => {
          return (
            <div>
              <Flex>
                <Text>{i + 1}.</Text>
                <Stack>
                  <div dangerouslySetInnerHTML={{ __html: x.text }}></div>
                  {x.questions.map((y: any) => {
                    return y.options.map((k: any) => {
                      return (
                        <Flex>
                          <Radio checked={k.isCorrect}></Radio>
                          <div
                            dangerouslySetInnerHTML={{ __html: k.text }}
                          ></div>
                        </Flex>
                      );
                    });
                  })}
                </Stack>
              </Flex>
              <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>
              <Divider />
            </div>
          );
        })}
        <Text fz={25} fw={700}>
          Integer Based
        </Text>
        {intergerbased.map((x, i) => {
          return (
            <div>
              <Flex>
                <Text>{i + 1}.</Text>
                <Stack>
                  <div dangerouslySetInnerHTML={{ __html: x.text }}></div>
                  <div dangerouslySetInnerHTML={{ __html: x.answer }}></div>
                </Stack>
              </Flex>
              <div dangerouslySetInnerHTML={{ __html: x.solution }}></div>
              <Divider />
            </div>
          );
        })}
      </Stack>
    </Container>
  );
}
