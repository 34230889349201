import { Stack, Textarea, useMantineTheme } from "@mantine/core";
import { useState } from "react";
import { Button, Checkbox, Container, Group, TextInput } from "@mantine/core";

function AnswerOption(props: {
  checked: boolean;
  onTextChange: (updatedValue: string) => void;
  onCheckboxValueChange: () => void;
}) {
  return (
    <Group style={{ padding: 2, margin: 2, width: "100vw" }}>
      <Checkbox
        checked={props.checked}
        onChange={props.onCheckboxValueChange}
        style={{ padding: 10, margin: 2 }}
      />
      <Textarea
        minRows={2}
        style={{ width: "30vw" }}
        placeholder="Option"
        onChange={(e) => props.onTextChange(e.target.value)}
      ></Textarea>
    </Group>
  );
}

interface AddQuestionDialogProps {
  onSubmit: (data: AddQuestionDialogResultData) => void;
}

export function AddQuestionDialog(props: AddQuestionDialogProps) {
  enum AnswerOptionType {
    First,
    Second,
    Third,
    Fourth,
  }

  const [firstOptionText, setFirstOptionText] = useState<string>();
  const [secondOptionText, setSecondOptionText] = useState<string>();
  const [thirdOptionText, setThirdOptionText] = useState<string>();
  const [fourthOptionText, setFourthOptionText] = useState<string>();
  const [questionText, setQuestionText] = useState<string>();

  const theme = useMantineTheme();
  const [checkedState, setCheckedState] = useState([
    false,
    false,
    false,
    false,
  ]);

  function onSubmitClick() {
    var model: AddQuestionDialogResultData = {
      text: "",
      answers: [],
    };

    if (questionText == null || questionText.length <= 0) {
      console.log(
        `questionText == null => ${
          questionText == null
        } || questionText.length <= 0 => ${questionText!.length <= 0}`
      );

      return;
    }

    const checkedIndex = checkedState.findIndex((item) => item == true);

    if (checkedIndex == null || checkedIndex == -1) {
      console.log(
        `checkedIndex == null => ${
          checkedIndex == null
        } || checkedIndex == -1 => ${checkedIndex == -1}`
      );

      return;
    }

    if (
      firstOptionText == null ||
      secondOptionText == null ||
      thirdOptionText == null ||
      fourthOptionText == null
    ) {
      console.log(`
      firstOptionText == null => ${firstOptionText == null} ||
      secondOptionText == null => ${secondOptionText == null} ||
      thirdOptionText == null => ${thirdOptionText == null} ||
      fourthOptionText == null => ${fourthOptionText == null}
      `);

      return;
    }

    model.text = questionText;
    model.answers = [
      {
        text: firstOptionText,
        isCorrect: checkedIndex == 0,
      },
      {
        text: secondOptionText,
        isCorrect: checkedIndex == 1,
      },
      {
        text: thirdOptionText,
        isCorrect: checkedIndex == 2,
      },
      {
        text: fourthOptionText,
        isCorrect: checkedIndex == 3,
      },
    ];

    props.onSubmit(model);
  }

  function onCheckboxValueChange(type: AnswerOptionType) {
    switch (type) {
      case AnswerOptionType.First:
        setCheckedState([true, false, false, false]);
        break;
      case AnswerOptionType.Second:
        setCheckedState([false, true, false, false]);
        break;
      case AnswerOptionType.Third:
        setCheckedState([false, false, true, false]);
        break;
      case AnswerOptionType.Fourth:
        setCheckedState([false, false, false, true]);
        break;
    }
  }

  function getCheckboxValue(type: AnswerOptionType) {
    switch (type) {
      case AnswerOptionType.First:
        return checkedState[0];
      case AnswerOptionType.Second:
        return checkedState[1];
      case AnswerOptionType.Third:
        return checkedState[2];
      case AnswerOptionType.Fourth:
        return checkedState[3];
    }
  }
  return (
    <Stack>
      <Textarea
        minRows={2}
        placeholder="Question"
        onChange={(e) => setQuestionText(e.target.value)}
      ></Textarea>
      <Container style={{ paddingTop: 20 }} size="xl">
        <AnswerOption
          checked={getCheckboxValue(AnswerOptionType.First)}
          onCheckboxValueChange={() =>
            onCheckboxValueChange(AnswerOptionType.First)
          }
          onTextChange={(updatedValue) => setFirstOptionText(updatedValue)}
        />
        <AnswerOption
          checked={getCheckboxValue(AnswerOptionType.Second)}
          onCheckboxValueChange={() =>
            onCheckboxValueChange(AnswerOptionType.Second)
          }
          onTextChange={(updatedValue) => setSecondOptionText(updatedValue)}
        />
        <AnswerOption
          checked={getCheckboxValue(AnswerOptionType.Third)}
          onCheckboxValueChange={() =>
            onCheckboxValueChange(AnswerOptionType.Third)
          }
          onTextChange={(updatedValue) => setThirdOptionText(updatedValue)}
        />
        <AnswerOption
          checked={getCheckboxValue(AnswerOptionType.Fourth)}
          onCheckboxValueChange={() =>
            onCheckboxValueChange(AnswerOptionType.Fourth)
          }
          onTextChange={(updatedValue) => setFourthOptionText(updatedValue)}
        />
      </Container>
      <Button
        fullWidth
        onClick={onSubmitClick}
        style={{ backgroundColor: theme.colors.blue[3] }}
      >
        Submit
      </Button>
    </Stack>
  );
}
