import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface TopicState {
  loadingState: LoadingState;
  value?: Topic;
  error: any;
}

const initialState: TopicState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const fetchTopicDetails = createAsyncThunk(
  "subject/fetchTopicDetails",
  async (params: { topicId: string }) => {
    const response = await axios.get(`/api/v1/topic/${params.topicId}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData: Topic = response.data;
      console.log(responseData);

      return responseData;
    }

    return null;
  }
);
export const updateTopicNameById = createAsyncThunk(
  "Topic/UpdateName",
  async (params: { id: string; name: string }) => {
    const response = await axios.put(
      `/api/v1/topic/${params.id}`,
      {
        name: params.name,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);
export const topicSlice = createSlice({
  name: "Topic",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopicDetails.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(fetchTopicDetails.fulfilled, (state, action) => {
        if (action.payload != null) state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(fetchTopicDetails.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default topicSlice.reducer;
