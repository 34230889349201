import {
  Button,
  Stack,
  Textarea,
  TextInput,
  useMantineTheme,
  LoadingOverlay,
  Modal,
  Text,
  Flex
} from "@mantine/core";
import { AddTheoryDialog } from "../../Dialogs/AddTheoryDialog";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import TurndownService from "turndown";
import { stateToHTML } from "draft-js-export-html";
import { stateFromMarkdown } from "draft-js-import-markdown";
import { useDispatch } from "react-redux";
import { useEffect, useState,Fragment } from "react";
import { AppDispatch } from "../../../store/ReduxStore";
import { uploadFile } from "../../../features/uploadFile/uploadFileSlice";


function extractBase64StringsFromString(inputString:string) {
  const regex = /<img[^>]+src="data:image\/[^;]+;base64,([^">]+)"/g;
  const matches = inputString.match(regex);
  if (matches) {
    const base64Images = matches.map((match) => {
      const srcMatch = match.match(/src="([^">]+)"/);
      if (srcMatch && srcMatch[1]) {
        return srcMatch[1];
      }
      return "";
    });
    return base64Images;
  }
  return [];
}

function base64StringToBlob(base64String:any, fileName:any) {
  const byteString = atob(base64String.split(',')[1]);
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new File([uint8Array], fileName, { type: mimeString });
}

function addStylingToImage(htmlString: string): string {
  const regex = /<img[^>]+data-size="([^">]+)"/g;
  const matches = htmlString.match(regex);
  if (matches) {
    let modifiedHtmlString = htmlString;
    matches.forEach((match) => {
      const imageSizeMatch = match.match(/data-size="([^">]+)"/);
      if (imageSizeMatch && imageSizeMatch[1]) {
        const imageSize = imageSizeMatch[1];
        const [width, height] = imageSize.split(",");
       
        const style = `width: ${width}; height: ${height}; display: block;`;
        modifiedHtmlString = modifiedHtmlString.replace(
          match,
          `${match} style="${style}"`
        );
      }
    });
    return modifiedHtmlString;
  }
  return htmlString;
}


const addTableBorder = (htmlString:string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const tdElements = doc.getElementsByTagName('td');
  for (let i = 0; i < tdElements.length; i++) {
    const td = tdElements[i];
    td.setAttribute('style', 'border: 1px solid black;padding:10px 5px;');
  }
  const trElements = doc.getElementsByTagName('tr');
  for (let i = 0; i < trElements.length; i++) {
    const tr = trElements[i];
    tr.setAttribute('style', 'border: 1px solid black;');
  }

  // Convert the modified HTML back to a string
  const modifiedHtmlString = doc.documentElement.outerHTML;

  // Return the modified HTML string
  return modifiedHtmlString;
};
const addInlineStylingtoLI = (htmlString:string) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  const liElements = tempElement.querySelectorAll('li');

  liElements.forEach((li) => {
    li.style.fontSize = '18px';
  });

  const updatedHtmlString = tempElement.innerHTML;

  return updatedHtmlString
}

const convertClassToClassName = (htmlString:string) => {
  const convertedString = htmlString.replace(/class=/g, 'className=');
  return convertedString;
};

interface EditTheoryModalForHtmlProps {
  text: string | undefined;
  setLoadingData: (data: boolean) => void;
  onSubmit: (theory: string) => void;
}
function EditTheoryModalForHtml(props: EditTheoryModalForHtmlProps) {
  const dispatch=useDispatch<AppDispatch>();

  const [htmlContentString, sethtmlContentString] = useState(
    props.text ?? ""
  );
  const [onDataUpload, setUpload] = useState(false);

  function handleChange(content: any) {
    sethtmlContentString(content);
  }
  useEffect(()=>{
    if(onDataUpload===true) {
      var string1;
     string1=addTableBorder(htmlContentString);
      string1=addStylingToImage(string1)
     string1=convertClassToClassName(string1)
     string1=addInlineStylingtoLI(string1)
      props.onSubmit(string1);
      setUpload(false);
    }
  },[onDataUpload,htmlContentString])

  
  async function formHandler(e:any) {
    e.preventDefault();
      const images = extractBase64StringsFromString(htmlContentString);
      
      let i=0;
      for(i=0;i<images.length;i++){
        props.setLoadingData(true);
        const blob = base64StringToBlob(images[i], 'image.png');
        await dispatch(uploadFile({ file: blob }))
        .unwrap()
        .then((data) => {
          props.setLoadingData(false);
          console.log(data.url)
          sethtmlContentString((prev) => {
            const prev1 = prev.replace(images[i], data.url);
            return prev1;
          });
        })
        .catch((error) => {
          props.setLoadingData(false);
          console.log("====================================");
          console.log(error);
          console.log("====================================");
        });
      }
        setUpload(true);
    }
  return (
    <Flex direction="column"
      style={{
        width:'100%',
        
      }}
    >
      <Text fz={20} fw={700} mb={10}>
        Edit Theory :
      </Text>

      <form onSubmit={formHandler}>
        <Stack
        w={'100%'}
        h={'100%'}
        
        >
          {htmlContentString !==undefined && (
            <SunEditor
              autoFocus={true}
              lang="en"
              setOptions={{
                showPathLabel: false,
                // minHeight: "50vh",
                // maxHeight: "50vh",
                height:'100vh',
                
                placeholder: "Enter your text here!!!",
                plugins: [
                  align,
                  font,
                  fontColor,
                  fontSize,
                  formatBlock,
                  hiliteColor,
                  horizontalRule,
                  lineHeight,
                  list,
                  paragraphStyle,
                  table,
                  template,
                  textStyle,
                  image,
                  link,
                ],
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["paragraphStyle"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  ["removeFormat"],
                  "/",
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link", "image"],
                ],
                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                font: [
                  "Arial",
                  "Calibri",
                  "Comic Sans",
                  "Courier",
                  "Garamond",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Palatino Linotype",
                  "Segoe UI",
                  "Tahoma",
                  "Times New Roman",
                  "Trebuchet MS",
                ],
                imageResizing: true,
              }}
              onChange={handleChange}
              setContents={htmlContentString}
            />
          )}

          <Flex justify="center">
            <Button type="submit" my={15} ta="center" size="lg">
              Submit
            </Button>
          </Flex>
        </Stack>
      </form>
    </Flex>
  );
}
interface EditTheoryModalForMarkProps {
  topic?: Topic;
  setLoadingData: (data: boolean) => void;
  onSubmit: (theory: string) => void;
}
function EditTheoryModalForMark(props: EditTheoryModalForMarkProps) {
  const [editorContent, setEditorContent] = useState<string>("");
  const [onDataUpload, setUpload] = useState(false);
  const dispatch=useDispatch<AppDispatch>();
  function handleChange(content: any) {
    console.log(content) 
    setEditorContent(content)
    // const turndownService = new TurndownService();
    // const markdown = turndownService.turndown(content);
    // setMarkdownContent(markdown);
  }
    useEffect(()=>{
    if(onDataUpload===true) {
      props.onSubmit(editorContent);
      setUpload(false);
    }
  },[onDataUpload])
  useEffect(()=>{
    setEditorContent(props.topic?.theoryUrl ?? "")
  },[props.topic])
  // useEffect(() => {
  //   setMarkdownContent(props.topic?.theoryUrl ?? "");
  // }, [props.topic?.theoryUrl]);

  // useEffect(() => {
  //   const html = stateFromMarkdown(markdownContent);
  //   let html2 = stateToHTML(html);
  //   if(markdownContent!=="") setEditorContent(html2);
  // }, [markdownContent]);
  
async function formHandler(e:any) {
  e.preventDefault();
    const images = extractBase64StringsFromString(editorContent);

    for(let i=0;i<images.length;i++){
      props.setLoadingData(true);
      const blob = base64StringToBlob(images[i], 'image.png');
      dispatch(uploadFile({ file: blob }))
      .unwrap()
      .then((data) => {
        props.setLoadingData(false);
        setEditorContent((prev)=>{
          const prev1=prev.replace(images[i],data.url)
          return prev1;
        })
        setUpload(true);
      })
      .catch((error) => {
        props.setLoadingData(false);
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
    }
  }

  return (
    <Flex direction="column">
      <Text fz={20} fw={700} mb={10}>
        Edit Theory :
      </Text>

      <form onSubmit={formHandler}>
        <Stack>
            <SunEditor
              autoFocus={true}
              lang="en"
              setOptions={{
                showPathLabel: false,
                minHeight: "50vh",
                maxHeight: "50vh",
                placeholder: "Enter your text here!!!",
                plugins: [
                  align,
                  font,
                  fontColor,
                  fontSize,
                  formatBlock,
                  hiliteColor,
                  horizontalRule,
                  lineHeight,
                  list,
                  paragraphStyle,
                  table,
                  template,
                  textStyle,
                  image,
                  link,
                ],
                buttonList: [
                  ["undo", "redo"],
                  ["font", "fontSize", "formatBlock"],
                  ["paragraphStyle"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                  ],
                  ["fontColor", "hiliteColor"],
                  ["removeFormat"],
                  "/",
                  ["outdent", "indent"],
                  ["align", "horizontalRule", "list", "lineHeight"],
                  ["table", "link", "image"],
                ],
                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                font: [
                  "Arial",
                  "Calibri",
                  "Comic Sans",
                  "Courier",
                  "Garamond",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Palatino Linotype",
                  "Segoe UI",
                  "Tahoma",
                  "Times New Roman",
                  "Trebuchet MS",
                ],
                imageResizing: false,
              }}
              onChange={handleChange}
              setContents={editorContent}
            />

          <Flex justify="center">
            <Button type="submit" my={15} ta="center" size="lg">
              Submit
            </Button>
          </Flex>
        </Stack>
      </form>
    </Flex>
  );
}
interface TopicTheoryModalProps {
  onSubmit: (theory: string) => void;
  text: string | undefined;
}
export function TopicTheoryModal(props: TopicTheoryModalProps) {
  const [isLoading,setisLoading]=useState(false);
  return (
   <Fragment>
    <LoadingOverlay
      visible={isLoading}
    />
    {
      props.text!==undefined &&
      <EditTheoryModalForHtml
        text={props.text}
        setLoadingData={setisLoading}
        onSubmit={props.onSubmit}
      />
    }
      
   </Fragment>
      
  );
}
