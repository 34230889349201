import { createSlice } from "@reduxjs/toolkit";



const intitalproductState={
   isAdminAuthorizedModal:false
}
export const authorizationModel=createSlice({
    name:'authorizationModal',
    initialState:intitalproductState,
    reducers:{
        setModal(state,actions){
            state.isAdminAuthorizedModal=actions.payload;
        },
    }
})
export default authorizationModel.reducer