import { Modal } from "@mantine/core";
import { AddSimulationDataDialog } from "../Dialogs/AddSimulationData";


interface AddSimulationDataModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: {
    name: string;
    dataFile?: File | null | undefined;
    loaderFile?: File | null | undefined;

    frameworkFile?: File | null | undefined;
    codeFile?: File | null | undefined;
    thumbnailImage?: File | null | undefined;
  }) => void;
}

export function CreateSimulationDataModal(props: AddSimulationDataModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="lg"
      title="Add Simulation Data"
      overflow="inside"
      centered
    >
      <AddSimulationDataDialog onSubmitClick={props.onSubmit} />
    </Modal>
  );
}
