import { Button, Stack, TextInput, useMantineTheme } from "@mantine/core";
import { useState } from "react";

interface AddClassDialogProps {
  prefilledClassName?: string;
  sortOrder?: string;
  buttonTitle?: string;
  onSubmitClick: (className: string, order: string) => void;
}
export function AddClassDialog(props: AddClassDialogProps) {
  const [className, setClassName] = useState<string>(
    props.prefilledClassName ?? ""
  );
  const [order, setOrder] = useState<string>(props.sortOrder ?? "");
  const theme = useMantineTheme();
  return (
    <Stack>
      <TextInput
        label="Class name"
        withAsterisk
        value={className}
        onChange={(e) => setClassName(e.target.value)}
      />
      <TextInput
        label="Order"
        type="number"
        withAsterisk
        value={order}
        onChange={(e) => setOrder(e.target.value)}
      />
      <Button
        fullWidth
        onClick={() => props.onSubmitClick(className ?? "", order ?? "-1")}
        style={{ backgroundColor: theme.colors.violet[3] }}
      >
        {props.buttonTitle ?? "Submit"}
      </Button>
    </Stack>
  );
}
