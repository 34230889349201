import { Fragment, useEffect, useState } from "react";
import { TopicTheoryModal } from "../components/TopicDetails/Modals/TopicTheoryModal";
import { Button, Center, Flex, LoadingOverlay, Stack, Textarea } from "@mantine/core";
import { fetchTopicDetails } from "../features/topics/topicSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import { useNavigate, useParams } from "react-router-dom";
import { addTheoryToTopic } from "../features/topics/addTheoryToTopicSlice";
import { fetchChapterById } from "../features/chapters/getChapterByIdSlice";
import { updateChapterIntoById } from "../features/chapters/updateChapterSlice";
import { IconChevronsDownLeft } from "@tabler/icons";
import { AddNewBlogModal } from "../components/TopicDetails/Modals/AddNewBlogModal";
import {
  Createblogs,
  EditBlog,
  FetchBlogById,
} from "../features/blog/blogSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
import { EditBlogModal } from "../components/TopicDetails/Modals/EditBlogModal";
const adminAuthorizationActions = authorizationModel.actions;

export function EditBlogPage() {
  const params = useParams();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [addblogContent, setaddBlogContent] = useState<boolean>(false);
  const [data1, setData] = useState<any>();
  const [blog, setBlog] = useState<Blog | null>(null);
  const [theoryData, setTheoryData] = useState<string>("");
  useEffect(() => {
    if (params.id)
      dispatch(FetchBlogById(params.id))
      .unwrap()
        .then((data:any) => {
          console.log(data);
          setBlog(data)
        })
        .catch((err) => {});
  }, []);

  useEffect(()=>{
    if(blog)
      setTheoryData(blog?.blog)
  },[blog])
  async function editBlogHandler(data: {
    title: string;
    imgUrl: string;
    data: string;
    isHtml:boolean
  }) {
    setLoadingData(true);
    console.log(params.id)
    if (params.id)
      dispatch(
        EditBlog({
          imgUrl: data.imgUrl,
          title: data.title,
          data: data.data,
          id: params.id,
          isHtml:data.isHtml
        })
      )
        .unwrap()
        .then((data: any) => {
          setLoadingData(false);
          console.log(data);
          setBlog(data);
        })
        .catch((error: any) => {
          dispatch(adminAuthorizationActions.setModal(true));
          setLoadingData(false);
          console.log(error);
        });
  }
  return (
    <Fragment>
      {!addblogContent && (
        <Flex w={"100%"} h={"100vh"}>
          <Center mx="auto">
            <EditBlogModal
              blog={blog}
              setEditBlog={setaddBlogContent}
              setData={setData}
            />
          </Center>
          <LoadingOverlay visible={loadingData} />
        </Flex>
      )}

      {addblogContent && (
        <Stack h={"100%"} w={"100%"}>
          <TopicTheoryModal
            text={blog?.blog ?? ""}
            onSubmit={(data) => {
                console.log(data)
              editBlogHandler({
                title: data1.title,
                imgUrl: data1.imgUrl,
                data: data,
                isHtml:false
              });
              setaddBlogContent(false);
            }}
          />
        </Stack>
      )}
      {
        blog?.isHtml &&
        <>
      <Textarea
        value={theoryData}
        onChange={(e) => {
          setTheoryData(e.target.value);
        }}
        autosize
      />
      <div dangerouslySetInnerHTML={{ __html: theoryData }} />
      <Button
        onClick={() => {
          editBlogHandler({
            title: data1.title,
            imgUrl: data1.imgUrl,
            data: theoryData,
            isHtml:true
          });
        }}
      >
        Submit
      </Button>
    </>
}
    </Fragment>
  );
}
