import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Center,
  useMantineTheme,
} from "@mantine/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { User } from "../@types/User";
import { loginUser } from "../features/auth/loginSlice";
import { AppDispatch } from "../store/ReduxStore";
import {
  SaveValueToLocalStorage,
  LocalStorageKey,
} from "../utilities/LocalstorageUtility";

export function Login() {
  const theme = useMantineTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  enum ValueChangeFieldType {
    email,
    password,
  }

  const [loginDetails, setLoginDetails] = useState<LoginFormDetails>({
    email: "",
    password: "",
  });

  const onUpdateField = (
    fieldType: ValueChangeFieldType,
    updatedValue: string
  ) => {
    var nextFormState;
    if (fieldType === ValueChangeFieldType.email) {
      nextFormState = {
        ...loginDetails,
        email: updatedValue,
      };
    } else if (fieldType === ValueChangeFieldType.password) {
      nextFormState = {
        ...loginDetails,
        password: updatedValue,
      };
    }
    if (nextFormState != null) setLoginDetails(nextFormState);
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    dispatch(
      loginUser({ email: loginDetails.email, password: loginDetails.password })
    )
      .unwrap()
      .then(
        (
          data: {
            token: string;
            status: number;
            user: User;
          } | null
        ) => {
          if (data != null) {
            SaveValueToLocalStorage(
              LocalStorageKey.User,
              JSON.stringify(data.user)
            );
            SaveValueToLocalStorage(LocalStorageKey.Token, data.token);
            navigate("/");
          }
        }
      )
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
    // props.OnLoginClick(loginDetails);
  };

  return (
    <Center m={0}>
      <Container
        style={{
          minHeight: 900,
          minWidth: 450,
          maxWidth: 450,
          paddingTop: 100,
        }}
      >
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Welcome back HERO!
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Email"
            placeholder="you@vignam.com"
            required
            value={loginDetails.email}
            onChange={(e) =>
              onUpdateField(ValueChangeFieldType.email, e.target.value)
            }
          />
          <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            value={loginDetails.password}
            onChange={(e) =>
              onUpdateField(ValueChangeFieldType.password, e.target.value)
            }
          />

          <Button fullWidth mt="xl" onClick={onSubmitForm}>
            Sign in
          </Button>
        </Paper>
      </Container>
    </Center>
  );
}
