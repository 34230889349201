import { Navigate } from "react-router-dom";

import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "./LocalstorageUtility";
import { useEffect,useState,Fragment } from "react";
import { authorizeUser } from "../features/auth/loginSlice";
import { useDispatch, useSelector, } from "react-redux";
import { AppDispatch, RootState } from "../store/ReduxStore";
import {Modal,Text} from '@mantine/core'
import { authorizationModel } from "../store/adminAuthorizationSlice";
const adminAuthorizationActions=authorizationModel.actions;
export const ProtectedRoute = ({ children }: any) => {
  const dispatch = useDispatch<AppDispatch>();  
  const [authorizedUser,setAuthorizedUser]=useState('')
  const isUnAuthorizedModal=useSelector<RootState,boolean>(state=>{
    return state.adminAuthorizationSlice.isAdminAuthorizedModal
  })
   const fetchAuthorization=async()=>{
     dispatch(authorizeUser())
     .unwrap()
     .then((data)=>{
        setAuthorizedUser(data)
     })
     .catch((err)=>{
        setAuthorizedUser(err);
     })
  }
  useEffect(()=>{
    fetchAuthorization();
  },[])
  if (authorizedUser === 'AUTHORIZED') {
    return (
      <Fragment>
      <div>
        {children}
      </div>
      <Modal
            opened={isUnAuthorizedModal}
            onClose={()=>{
                dispatch(adminAuthorizationActions.setModal(false))
            }}
            centered
        >
            <Text color="red">Unauthorized</Text>
            <Text color="red">Only Admin has Access TO Delete Items</Text>
        </Modal>
      </Fragment>
    );
  }
  else if(authorizedUser !== '')
    return <Navigate to="/login" />;
  return(
    <div></div>
  )
  
};
