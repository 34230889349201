import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../utilities/LocalstorageUtility";

export async function getAllBoards() {
  const response = await axios.get(`/api/v1/board/getAll`, {
    headers: {
      authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
    },
  });

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
export async function CreateBoard(name: string) {
  const response = await axios.post(
    `/api/v1/board/create`,
    { name: name },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
export async function getBoardAndClasses(boardId: string) {
  const response = await axios.get(`/api/v1/board/getSingleBoard/${boardId}`, {
    headers: {
      authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
    },
  });

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
export async function getAllSubjectsForClass(classID: string) {
  const response = await axios.get(`/api/v1/class/getAllSubjects/${classID}`, {
    headers: {
      authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
    },
  });

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
export async function getAllChaptersForSubject(subjectID: string) {
  const response = await axios.get(
    `/api/v1/subject/subjectWithChapters/${subjectID}`,
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
export async function AddChapterToSubject(
  subjectId: string,
  chapterId: string
) {
  const response = await axios.post(
    `/api/v1/subject/addChapterToSubject/${subjectId}`,
    { chapterId: chapterId },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status === 200) {
    const responseData = response.data;
    return responseData;
  }

  return null;
}
