import { Modal } from "@mantine/core";
import { EditSimulationDataDialog } from "../Dialogs/EditSimulationDialog";
import {
  Button,
  FileInput,
  Stack,
  TextInput,
  useMantineTheme,
  Flex,
  ScrollArea,
  Text,
  MultiSelect,
  Group,
  LoadingOverlay,
} from "@mantine/core";
import { getSimulationList } from "../../features/simulation/getSimulationListSlice";
import { getSimulationFilters } from "../../features/simulation/getSimulationSlice";
import { useState, useEffect, Fragment } from "react";
import { AppDispatch } from "../../store/ReduxStore";
import { useDispatch } from "react-redux";
import { it } from "node:test";
interface AddSimulationDataModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: {
    name: string;
    dataFile?: File | null | undefined;
    loaderFile?: File | null | undefined;

    frameworkFile?: File | null | undefined;
    codeFile?: File | null | undefined;
    thumbnailImage?: File | null | undefined;
  }) => void;
}

export function EditSimulationDataModal(props: AddSimulationDataModalProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispath = useDispatch<AppDispatch>();
  const [simulations, setSimulations] = useState<SimulationListData[]>([]);
  const [simulationFilters, setSimulationFilters] = useState<
    SimulationFilterData[]
  >([]);
  function fetchSimulationList() {
    setLoading(true);
    dispath(getSimulationList())
      .unwrap()
      .then((data: SimulationData[]) => {
        setLoading(false);
        setSimulations([]);
        const simulationListData: SimulationListData[] = [];
        data.forEach((item:any) => {
          simulationListData.push({
            _id: item._id,
            label: item.name,
            thumbnailUrl: item.thumbnailImageUrl,
            value: item._id,
            description: item.description,
            simultionfilters: item.simultionfilters,
            isActivity: item.isActivity,
            isSimulation: item.isSimulation,
            simulationDescription: item.simulationDescription,
            simulationfeatures: item.simulationfeatures,
            simulationTags: item.simulationTags,
            simulationBackgroundColor: item.simulationBackgroundColor,
            isSimulationPremium: item.isSimulationPremium,
            videoUrl:item.videoUrl
          });
        });
        setSimulations(simulationListData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  function fetchSimulationfilters() {
    setLoading(true);
    dispath(getSimulationFilters())
      .unwrap()
      .then((data: SimulationFiltersData[]) => {
        setLoading(false);
        const simulationListData: SimulationFilterData[] = [];
        data.forEach((item) => {
          if (item.subject !== "All Simulations") {
            simulationListData.push({
              value: item._id,
              label: item.subject,
            });
          }
        });
        setSimulationFilters(simulationListData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  useEffect(() => {
    if (props.opened === true) fetchSimulationList();
  }, [props.opened]);
  useEffect(() => {
    if (props.opened === true) fetchSimulationfilters();
  }, [props.opened]);
  return (
    <Fragment>
      <LoadingOverlay visible={isLoading} />

      <Modal
        opened={props.opened}
        onClose={props.onClose}
        // size="lg"
        title="Edit Simulation Data"
        // overflow="inside"
        centered
        size="auto"
      >
        <EditSimulationDataDialog
          filters={simulationFilters}
          simulations={simulations}
          setSimulations={(id: string) => {
            setSimulations((prev) => {
              const prev1 = prev.filter((x) => x._id !== id);
              return prev1;
            });
          }}
        />
      </Modal>
    </Fragment>
  );
}
