import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface ClassListState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: ClassListState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const createClass = createAsyncThunk(
  "classes/create",
  async (
    params: { className: string; order: number; boardId: string },
    dispatch
  ) => {
    const response = await axios.post(
      "/api/v1/class/create",
      {
        name: params.className,
        sortOrder: params.order,
        boardId: params.boardId,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const createClassSlice = createSlice({
  name: "Create Class Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createClass.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(createClass.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(createClass.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default createClassSlice.reducer;
