import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface ChapterState {
  loadingState: LoadingState;
  value?: Chapter[];
  error: any;
}

const initialState: ChapterState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const fetchChapters = createAsyncThunk(
  "subject/fetchChapter",
  async (subjectId: string) => {
    const response = await axios.get(`/api/v1/chapter/subject/${subjectId}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data.chapters;

      return responseData;
    }

    return [];
  }
);

export const chapterSlice = createSlice({
  name: "Chapter",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChapters.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(fetchChapters.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(fetchChapters.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export const UploadLessonPlan = createAsyncThunk(
  "classes/Update",
  async (params: { chapterId: string; fileName: string; url: String }) => {
    const response = await axios.put(
      `/api/v1/chapter/addDefaultChapterLessonPlans/${params.chapterId}`,
      {
        fileName: params.fileName,
        url: params.url,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const UploadObjectives = createAsyncThunk(
  "classes/Update",
  async (params: { chapterId: string; objectives: string[] }) => {
    const response = await axios.put(
      `/api/v1/chapter/addObjectives/${params.chapterId}`,
      {
        objectives: params.objectives,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const UploadQuestions = createAsyncThunk(
  "classes/Update",
  async (params: {
    chapterId: string;
    matchthefollowingQuestions: any[];
    mcqQuestions: any[];
    assertiveQuestions: any[];
    intergerBasedQuestions: any[];
    multipleCorrectAnswerquestions: any[];
    linkedComprehensionBasedQuestions: any[];
  }) => {
    const response = await axios.put(
      `/api/v1/chapter/addQuestionsFromWord/${params.chapterId}`,
      {
        mcqQuestions: params.mcqQuestions,
        matchthefollowing: params.matchthefollowingQuestions,
        assertionReasoning: params.assertiveQuestions,
        linkedComprehensionBasedQuestions:
          params.linkedComprehensionBasedQuestions,
        intergerBasedQuestions: params.intergerBasedQuestions,
        multipleCorrectAnswerquestions: params.multipleCorrectAnswerquestions,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const CopyQuestions = createAsyncThunk(
  "classes/Update",
  async (params: {
    sourceChapterId: string;
    destinationChapterId: string;
    attributes: string[];
    difficulityLevel: string;
    sourceDifficultyType: string;
  }) => {
    const response = await axios.post(
      `/api/v1/chapter/transferQuestions/`,
      {
        sourceChapterId: params.sourceChapterId,
        destinationChapterId: params.destinationChapterId,
        attributes: params.attributes,
        difficulityLevel: params.difficulityLevel,
        sourceDifficultyType: params.sourceDifficultyType,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export default chapterSlice.reducer;
