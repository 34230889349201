import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface GetSubjectByIdState {
  loadingState: LoadingState;
  value?: SimulationData;
  error: any;
}

const initialState: GetSubjectByIdState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const updateSimulationById = createAsyncThunk(
  "simulation/update",
  async (params: { SimulationId: string; filters: string[] }) => {
    const response = await axios.put(
      `/api/v1/simulation/${params.SimulationId}/filters`,
      {
        filters: params.filters,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const updateSimulationDataById = createAsyncThunk(
  "simulation/update",
  async (params: {
    SimulationId: string;
    features: string[];
    description: string;
    tags: string[];
  }) => {
    const response = await axios.put(
      `/api/v1/simulation/${params.SimulationId}/simulationData`,
      {
        features: params.features,
        description: params.description,
        tags: params.tags,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const updateSimulationTypeById = createAsyncThunk(
  "simulation/update",
  async (params: {
    SimulationId: string;
    isActivity: boolean;
    isSimulation: boolean;
  }) => {
    const response = await axios.put(
      `/api/v1/simulation/${params.SimulationId}/type`,
      {
        isActivity: params.isActivity,
        isSimulation: params.isSimulation,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const updateSimulationBackgroundColorById = createAsyncThunk(
  "simulation/update",
  async (params: { SimulationId: string; color: String }) => {
    const response = await axios.put(
      `/api/v1/simulation/${params.SimulationId}/backgroundColor`,
      {
        color: params.color,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const updateSimulationVideoUrlById = createAsyncThunk(
  "simulation/update",
  async (params: { SimulationId: string; videoUrl: String }) => {
    const response = await axios.put(
      `/api/v1/simulation/${params.SimulationId}/addvideo`,
      {
        videoUrl: params.videoUrl,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export async function updateSimulationPremiumById(params: {
  SimulationId: string;
  isPremium: boolean;
}) {
  const response = await axios.put(
    `/api/v1/simulation/${params.SimulationId}/premium`,
    {
      isPremium: params.isPremium,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}

export const updateSimulationsByIdSlice = createSlice({
  name: "Update Simulation Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateSimulationById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(updateSimulationById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(updateSimulationById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export async function addChapterToSimulation(params: {
  SimulationIds: string[];
  chapterName: string;
  chapterId: string;
}) {
  const response = await axios.put(
    `/api/v1/simulation/addTags/chapter`,
    {
      simulationIds: params.SimulationIds,
      chapterName: params.chapterName,
      chapterId: params.chapterId,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
export default updateSimulationsByIdSlice.reducer;
