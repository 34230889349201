import {
  Container,
  Paper,
  Title,
  Text,
  Group,
  Checkbox,
  Button,
  Modal,
  TextInput,
  Radio,
  Stack,
  Textarea,
  Image,
} from "@mantine/core";
import { useEffect, useState } from "react";
import {
  updateQuestionById,
  updateQuestionImagesById,
} from "../../features/questions/editquestionSLice";

interface QuestionCardProps {
  question: TopicQuestion;
  indexN: number;
}

export function QuestionCard(props: QuestionCardProps) {
  function correctAnswerNumber() {
    let n = 0;
    props.question.answers.map((x, index) => {
      if (x.isCorrect) n = index;
    });
    return n;
  }

  const [editImageModalOpened, setImageEditModalOpened] =
    useState<boolean>(false);
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>(props.question.text);
  const [mcqcorrectans, setmcqcorrectans] = useState<number>(
    correctAnswerNumber()
  );
  const [answers, setanswers] = useState<
    { text: string; isCorrect: boolean }[]
  >(JSON.parse(JSON.stringify(props.question.answers)));

  const [questionImage, setquestionImage] = useState<string>(
    props.question.questionImageUrl
  );
  const [answerImages, setAnswerImages] = useState<string[]>([
    ...props.question.answerImageUrl,
  ]);

  function editQuestion() {
    answers.map((x, index) => {
      if (index == mcqcorrectans) x.isCorrect = true;
      else x.isCorrect = false;
    });
    updateQuestionById({
      QuestionId: props.question._id,
      questionText: questionText,
      answers: answers,
    });
  }
  function editQuestionImage() {
    updateQuestionImagesById({
      QuestionId: props.question._id,
      questionImage: questionImage,
      answerImages: answerImages,
    });
  }
  useEffect(() => {
    setmcqcorrectans(correctAnswerNumber());
  }, []);
  return (
    <>
      <Paper shadow="xl" p="xl" radius="md" m="lg">
        <Button onClick={() => setEditModalOpened(true)}>Edit</Button>
        <Button
          mx={10}
          onClick={() => {
            setImageEditModalOpened(true);
          }}
        >
          Edit Images
        </Button>
        <div>
          <Title style={{ marginBottom: 10 }} order={3}>
            <Text mx={5} span>
              {props.indexN}.
            </Text>
            {props.question.text}
          </Title>
          <Image src={props.question.questionImageUrl} width={"50%"}></Image>
        </div>
        {props.question.answers.map((item, index) => (
          <Group>
            <Checkbox checked={item.isCorrect}></Checkbox>
            <Text>{item.text}</Text>
            <Image
              src={props.question.answerImageUrl[index]}
              width={"50%"}
            ></Image>
          </Group>
        ))}
      </Paper>
      <Modal
        size={"xl"}
        opened={editModalOpened}
        centered
        onClose={() => setEditModalOpened(false)}
      >
        <Text>Edit Question</Text>
        <Textarea
          autosize
          placeholder="Question"
          value={questionText}
          onChange={(e) => {
            setQuestionText(e.target.value);
          }}
        />
        <Radio.Group
          value={mcqcorrectans.toString()}
          onChange={(e) => {
            setmcqcorrectans(Number(e));
          }}
        >
          <Stack>
            {answers.map((answer, index) => {
              return (
                <Group key={index}>
                  <Radio value={index.toString()} />
                  <Textarea
                    w={400}
                    autosize
                    placeholder={"Option " + (index + 1)}
                    value={answers[index].text}
                    onChange={(e) => {
                      const ans = [...answers];
                      ans[index].text = e.target.value;
                      setanswers(ans);
                    }}
                  />
                </Group>
              );
            })}
          </Stack>
        </Radio.Group>
        <Button
          onClick={() => {
            editQuestion();
            setEditModalOpened(false);
          }}
        >
          Submit
        </Button>
      </Modal>

      <Modal
        size={"xl"}
        opened={editImageModalOpened}
        centered
        onClose={() => setImageEditModalOpened(false)}
      >
        <Text>Edit Question Image</Text>
        <Textarea
          autosize
          placeholder="Question Image Link"
          value={questionImage}
          onChange={(e) => {
            setquestionImage(e.target.value);
          }}
        ></Textarea>

        {answerImages.map((x, index) => {
          return (
            <>
              <Textarea
                my={30}
                autosize
                placeholder="Answer Image Link"
                value={answerImages[index] || ""}
                onChange={(e) => {
                  const ans = [...answerImages];
                  ans[index] = e.target.value;
                  setAnswerImages(ans);
                }}
              ></Textarea>
            </>
          );
        })}

        <Button
          onClick={() => {
            editQuestionImage();
            setImageEditModalOpened(false);
          }}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
}
