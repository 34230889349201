import {
  Button,
  Container,
  Group,
  LoadingOverlay,
  Title,
  Modal,
  Stack,
} from "@mantine/core";

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { TopicDetailsAddMenu } from "../components/TopicDetailsAddMenu";
import { addVideosToTopic } from "../features/topics/addVideoToTopicSlice";
import { fetchTopicDetails } from "../features/topics/topicSlice";
import { AppDispatch } from "../store/ReduxStore";
import { SimulationList } from "../components/TopicDetails/SimulationList";
import { VideosList } from "../components/TopicDetails/VideosList";
import { QuestionsList } from "../components/TopicDetails/QuestionsList";
import {
  ReviseTheoryModal,
  TheoryComponent,
  TheoryNewComponent,
} from "../components/TopicDetails/TheoryComponent";
import { TopicTheoryModal } from "../components/TopicDetails/Modals/TopicTheoryModal";
import { AddVideoModal } from "../components/TopicDetails/Modals/AddVideoModal";
import { AddQuestionModal } from "../components/TopicDetails/Modals/AddQuestionsModal";
import { DeleteConfirmationModal } from "../components/DeleteConfirmationModal";
import { deleteSimulationById } from "../features/simulation/deleteSimulationSlice";
import { deleteTopicById } from "../features/topics/deleteTopicSlice";
import "suneditor/dist/css/suneditor.min.css";

import {
  createQuestion,
  createSubjectiveQuestion,
} from "../features/questions/createQuestionSlice";
import {
  addTheoryToTopic,
  updateRevisedTheoryToTopic,
} from "../features/topics/addTheoryToTopicSlice";
import { UploadImageModal } from "../components/UploadImageModals/UploadImageModal";
import { ShowImageUrlModal } from "../components/UploadImageModals/ShowImageUrlModal";
import { AddSimulationToTopicModal } from "../components/TopicDetails/Modals/AddSimulationToTopicModal";
import { addSimulationsToTopic } from "../features/topics/addSimulationsToTopicSlice";
import { removeSimulationsFromTopic } from "../features/topics/removeSimulationsFromTopicSlice";
import { QuestionType } from "../@types/QuestionTypes.d";
import { AddSubjectiveQuestionModal } from "../components/TopicDetails/Modals/AddSubjectiveQModal";
import { SubjectiveQuestionsList } from "../components/TopicDetails/SubjectiveQuestionsList";
import { useLocation } from "react-router-dom";

const addTableBorder = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const tables = doc.getElementsByTagName("table");

  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];
    table.setAttribute("style", "border: 1px solid black;");

    const tableRows = table.getElementsByTagName("tr");
    for (let j = 0; j < tableRows.length; j++) {
      const tableCells = tableRows[j].getElementsByTagName("td");
      for (let k = 0; k < tableCells.length; k++) {
        tableCells[k].setAttribute("style", "border: 1px solid black;");
      }
    }
  }
  const modifiedHtmlString = doc.documentElement.outerHTML;
  return modifiedHtmlString;
};

export function TopicDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const topicId = params.topicId;
  const questionsTypes = [
    QuestionType.FillQues,
    QuestionType.LongQues,
    QuestionType.ShortQues,
  ];
  const [topic, setTopic] = useState<Topic | undefined>();
  const [showTheoryDialog, setShowTheoryDialog] = useState<boolean>(false);
  const [showVideosDialog, setShowVideosDialog] = useState<boolean>(false);
  const [showSimulationDialog, setShowSimulationDialog] =
    useState<boolean>(false);

  const [showQuestionDialog, setShowQuestionDialog] = useState<boolean>(false);
  const [showQuestionsDialog, setShowQuestionsDialog] =
    useState<boolean>(false);
  const [showsubjectiveQDialog, setShowSubjectiveQDialog] =
    useState<boolean>(false);
  const [selectedSubQuestionType, setSelectedSubQuestionType] =
    useState<QuestionType | null>(null);

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showUploadImageDialog, setShowUploadImageDialog] =
    useState<boolean>(false);
  const [showUploadFileUrlDialog, setShowUploadFileUrlDialog] =
    useState<boolean>(false);
  const [fileUploadUrl, setFileUploadUrl] = useState<string | null>(null);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [htmls, htmlsss] = useState<string>("");
  const [reviseTheoryModal, setReviseTheoryModal] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get("subjectId");

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [topic]);
  function fetchData() {
    if (topicId == null || topicId.length <= 0) {
      return;
    }
    setLoadingData(true);
    dispatch(fetchTopicDetails({ topicId: topicId }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        if (data != null) setTopic(data);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }

  function onTheoryClick() {
    setShowTheoryDialog(true);
  }

  function onVideosClick() {
    setShowVideosDialog(true);
  }

  function onSimulationsClick() {
    setShowSimulationDialog(true);
  }

  function onQuestionsClick() {
    setShowQuestionsDialog(true);
  }

  function onDeleteClick() {
    setShowDeleteDialog(true);
  }
  const convertClassToClassName = (htmlString: string) => {
    const convertedString = htmlString.replace(/class=/g, "className=");
    return convertedString;
  };
  function DidSubmitTheory(theory: string) {
    const theory1 = convertClassToClassName(theory);
    setShowTheoryDialog(false);
    if (topicId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(addTheoryToTopic({ theory: theory1, topicId: topicId }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  function DidSubmitVideoUrl(url: string) {
    setShowVideosDialog(false);
    if (topic?._id == null) {
      return;
    }
    setLoadingData(true);
    dispatch(addVideosToTopic({ topicId: topic?._id, urls: [url] }))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        console.log(error);

        setLoadingData(false);
      });
  }

  function DidSubmitQuestion(data: AddQuestionDialogResultData) {
    setShowQuestionDialog(false);
    if (topicId == null) {
      return;
    }
    setLoadingData(true);
    dispatch(
      createQuestion({
        text: data.text,
        topicId: topicId,
        answers: data.answers,
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        setLoadingData(false);
      });
  }
  function DidSubmitSubjectiveQuestion(data: AddSubjectiveQDialogResultData) {
    setShowSubjectiveQDialog(false);
    if (topicId == null || topic === undefined) {
      return;
    }
    setLoadingData(true);
    if (selectedSubQuestionType !== null) {
      dispatch(
        createSubjectiveQuestion({
          text: data.text,
          topicId: topicId,
          answer: data.answer,
          sortOrder: (topic.subjectiveQuestions.length + 1) | 0,
          type: selectedSubQuestionType.type,
        })
      )
        .unwrap()
        .then((data) => {
          setLoadingData(false);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          setLoadingData(false);
        });
    }
  }

  function DidConfirmDelete() {
    setShowDeleteDialog(false);
    if (topicId == null) return;
    setLoadingData(true);
    dispatch(deleteTopicById(topicId))
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        navigate(`/chapter/${data._id}`);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  function OnSimulationClick(id: string) {
    window.open(`/simulation/${id}`, "__blank");
  }

  function OnSimulationDeleteClick(id: string) {
    if (topicId == null) return;
    setLoadingData(true);
    console.log(topicId);
    console.log(subjectId);
    if (subjectId)
      removeSimulationsFromTopic({
        topicId: topicId,
        simulations: [id],
        subjectId,
      })
        .then((data) => {
          setLoadingData(false);
          fetchData();
        })
        .catch((error) => {
          setLoadingData(false);
          console.log(error);
        });
  }

  function onUploadImageClick() {
    setShowUploadImageDialog(true);
  }

  function DidSubmitSimulations(simulations: string[]) {
    setShowSimulationDialog(false);
    if (topicId == null) return;
    setLoadingData(true);
    dispatch(
      addSimulationsToTopic({ topicId: topicId, simulations: simulations })
    )
      .then((data) => fetchData())
      .catch((error) => {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      });
  }
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(topic?.theoryUrl ?? "", "text/html");
    const htmlElement = doc.body.firstChild;

    if (containerRef && containerRef.current && htmlElement) {
      containerRef.current.appendChild(htmlElement);
    }
  }, [topic]);
  return (
    <>
      <Container size="xl" fluid={true} p="xl">
        <Group position="apart">
          <Title>{topic?.name}</Title>
          <Group>
            <Button
              variant="outline"
              onClick={() => setReviseTheoryModal(true)}
            >
              Revise Theory
            </Button>
            <Button
              variant="outline"
              onClick={() => setShowUploadImageDialog(true)}
            >
              Upload Image
            </Button>
            <TopicDetailsAddMenu
              onTheoryClick={() => {
                navigate(`/editTheory/${topicId}`);
              }}
              onVideosClick={onVideosClick}
              onSimulationsClick={onSimulationsClick}
              onQuestionsClick={onQuestionsClick}
              onDeleteClick={onDeleteClick}
            />
          </Group>
        </Group>

        <TheoryNewComponent topic={topic}></TheoryNewComponent>
        <TheoryComponent topic={topic} />

        <VideosList topic={topic} />

        <SimulationList
          topic={topic}
          onClick={OnSimulationClick}
          onDeleteClick={OnSimulationDeleteClick}
        />
        <QuestionsList topic={topic} />
        <SubjectiveQuestionsList topic={topic} />
        <ReviseTheoryModal
          theory={topic?.revisedTheory || " "}
          opened={reviseTheoryModal}
          onClose={() => setReviseTheoryModal(false)}
          onSubmit={(text) => {
            setReviseTheoryModal(false);
            if (topic) {
              updateRevisedTheoryToTopic({ topicId: topic?._id, theory: text })
                .then(() => {
                  fetchData();
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }}
        ></ReviseTheoryModal>
        <AddVideoModal
          opened={showVideosDialog}
          onClose={() => setShowVideosDialog(false)}
          onSubmit={DidSubmitVideoUrl}
        />

        <AddSimulationToTopicModal
          opened={showSimulationDialog}
          onClose={() => setShowSimulationDialog(false)}
          onSubmit={(data) => {
            DidSubmitSimulations(data.selectedSimulations);
          }}
        />

        <AddQuestionModal
          opened={showQuestionDialog}
          onSubmit={DidSubmitQuestion}
          onClose={() => setShowQuestionDialog(false)}
        />

        <AddSubjectiveQuestionModal
          opened={showsubjectiveQDialog}
          onSubmit={DidSubmitSubjectiveQuestion}
          onClose={() => setShowSubjectiveQDialog(false)}
          type={selectedSubQuestionType}
        />

        <Modal
          opened={showQuestionsDialog}
          onClose={() => {
            setShowQuestionsDialog(false);
          }}
        >
          <Stack>
            <Button
              onClick={() => {
                setShowQuestionDialog(true);
                setShowQuestionsDialog(false);
              }}
            >
              Add Mcq Questions
            </Button>
            {questionsTypes.map((x) => {
              return (
                <Button
                  onClick={() => {
                    setShowQuestionsDialog(false);
                    setShowSubjectiveQDialog(true);
                    setSelectedSubQuestionType(x);
                  }}
                >
                  Add {x.name}
                </Button>
              );
            })}
          </Stack>
        </Modal>
        <DeleteConfirmationModal
          opened={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          title="Are you sure you want to delete this topic?"
          onConfirm={DidConfirmDelete}
          onCancel={() => setShowDeleteDialog(false)}
        />

        <UploadImageModal
          opened={showUploadImageDialog}
          onClose={() => setShowUploadImageDialog(false)}
          onUploadSuccess={(url) => {
            setShowUploadImageDialog(false);
            setFileUploadUrl(url);
            setShowUploadFileUrlDialog(true);
          }}
        />

        <ShowImageUrlModal
          opened={showUploadFileUrlDialog}
          onClose={() => setShowUploadFileUrlDialog(false)}
          urlValue={fileUploadUrl ?? null}
        />
        <LoadingOverlay visible={loadingData} />
        {/* <div dangerouslySetInnerHTML={{ __html: topic?.theoryUrl ?? "" }}></div> */}
      </Container>
    </>
  );
}
