import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface UpdateChapterById {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: UpdateChapterById = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const updateChapterById = createAsyncThunk(
  "classes/Updte",
  async (params: { chapterId: string; chapterName: string; order: number }) => {
    const response = await axios.put(
      `/api/v1/chapter/${params.chapterId}`,
      {
        name: params.chapterName,
        sortOrder: params.order,
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);
export const updateChapterIntoById = createAsyncThunk(
  "classes/UpdateIntro",
  async (params: { chapterId: string,introduction:string }) => {
    console.log(params.chapterId)
    const response = await axios.put(
      `/api/v1/chapter/updateIntro/${params.chapterId}`,
      {
        introduction:params.introduction
      },
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const updateChapterByIdSlice = createSlice({
  name: "Update Chapter Slice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateChapterById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(updateChapterById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(updateChapterById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default updateChapterByIdSlice.reducer;
