import {
  Button,
  FileInput,
  Stack,
  TextInput,
  useMantineTheme,
  Flex,
  ScrollArea,
  Text,
  MultiSelect,
  Group,
  LoadingOverlay,
  Modal,
  Checkbox,
  Textarea,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateSimulationBackgroundColorById,
  updateSimulationById,
  updateSimulationDataById,
  updateSimulationPremiumById,
  updateSimulationTypeById,
  updateSimulationVideoUrlById,
} from "../../features/simulation/updateSimulationSlice";
import { AppDispatch } from "../../store/ReduxStore";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { deleteSimulationById } from "../../features/simulation/deleteSimulationSlice";
import { authorizationModel } from "../../store/adminAuthorizationSlice";
import { IconPlus } from "@tabler/icons";
const adminAuthorizationActions = authorizationModel.actions;
interface SimulationComponentProps {
  id: string;
  title: string;
  imgUrl: string | undefined;
  simulationFilterData: SimulationFilterData[];
  simulationDescription: string | undefined;
  simulationFeatures: string[] | undefined;
  simulationTags: string[] | undefined;
  currentFilters: string[];
  setLoadingData: (val: boolean) => void;
  setSimulations: (data: string) => void;
  isActivity: boolean;
  isPremium: boolean;
  isSimulation: boolean;
  simulationBackgroundColor: string;
  simulationVideoUrl:string
}
const SimulationComponent = (props: SimulationComponentProps) => {
  const [value, setValue] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const [deleteSimulationId, setDeleteSimulationd] = useState<string>("");

  const [isActivityChecked, setActivityChecked] = useState<boolean>(
    props.isActivity
  );
  const [isPremiumChecked, setPremiumChecked] = useState<boolean>(
    props.isPremium
  );
  const [isSimulationChecked, setSimulationChecked] = useState<boolean>(
    props.isSimulation
  );
  const [simulationTags, setSimulationTags] = useState<string[]>([]);
  const [simulationDescription, setSimulaitonDescription] =
    useState<string>("");
  const [simualtionFeatures, setSimuationFeatures] = useState<string[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [colorValue, setColorValue] = useState<string>(
    props.simulationBackgroundColor
  );
  const [videoUrl, setvideoUrl] = useState<string>(
    props.simulationVideoUrl
  );
  useEffect(() => {
    const values = props.currentFilters.map((x) => {
      return x;
    });
    setValue(values);
  }, [props.currentFilters]);

  function submitHandler() {
    props.setLoadingData(true);
    dispatch(
      updateSimulationById({
        SimulationId: props.id,
        filters: value,
      })
    )
      .unwrap()
      .then((data) => {
        dispatch(
          updateSimulationTypeById({
            SimulationId: props.id,
            isActivity: isActivityChecked,
            isSimulation: isSimulationChecked,
          })
        )
          .unwrap()
          .then((x) => {
            props.setLoadingData(false);
            console.log(x);
          })
          .catch((e) => {
            props.setLoadingData(false);
            console.log(e);
          });
      })
      .catch((error) => {
        props.setLoadingData(false);
      });
  }
  function submitHandler2() {
    props.setLoadingData(true);
    dispatch(
      updateSimulationDataById({
        SimulationId: props.id,
        features: simualtionFeatures,
        tags: simulationTags,
        description: simulationDescription,
      })
    )
      .unwrap()
      .then((data) => {
        props.setLoadingData(false);
        console.log(data);
        setOpenEditModal(false);
        // props.setSimulations(data._id)
      })
      .catch((error) => {
        props.setLoadingData(false);
      });
  }
  function onDeleteClassConfirm() {
    props.setLoadingData(true);
    setDeleteSimulationd("");
    dispatch(deleteSimulationById(deleteSimulationId))
      .unwrap()
      .then((data) => {
        props.setLoadingData(false);
        props.setSimulations(props.id);
      })
      .catch((err) => {
        props.setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true));
      });
  }
  const addField = () => {
    setSimuationFeatures([...simualtionFeatures, ""]);
  };
  const addFiel2 = () => {
    setSimulationTags([...simulationTags, ""]);
  };

  const handleInputChange = (value: string, index: number) => {
    const newFields = [...simualtionFeatures];
    newFields[index] = value;
    setSimuationFeatures(newFields);
  };
  const handleInputChange2 = (value: string, index: number) => {
    const newFields = [...simulationTags];
    newFields[index] = value;
    setSimulationTags(newFields);
  };
  useEffect(() => {
    if (props.simulationFeatures) {
      setSimuationFeatures(props.simulationFeatures);
    }
    if (props.simulationDescription)
      setSimulaitonDescription(props.simulationDescription);
    if (props.simulationTags) setSimulationTags(props.simulationTags);
  }, []);

  function colorChangeHandler() {
    dispatch(
      updateSimulationBackgroundColorById({
        color: colorValue,
        SimulationId: props.id,
      })
    )
      .unwrap()
      .then((data) => {
        props.setLoadingData(false);
        // props.setSimulations(props.id);
      })
      .catch((err) => {
        props.setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true));
      });
  }
  function videoChangeHandler() {
    dispatch(
      updateSimulationVideoUrlById({
        SimulationId: props.id,
        videoUrl:videoUrl
      })
    )
      .unwrap()
      .then((data) => {
        props.setLoadingData(false);
        // props.setSimulations(props.id);
      })
      .catch((err) => {
        props.setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true));
      });
  }
  const [text, setText] = useState<string>("");
  const maxRows = 5; // Set your desired maximum number of rows
  const lineHeight = 24; // Set the line height in pixels (adjust as needed)

  return (
    <>
      <Flex
        // justify="space-between"
        style={{
          border: "blue 1px solid",
          borderRadius: "10px",
        }}
        px={10}
        py={10}
        my={10}
        w="60vw"
        align="center"
      >
        <Group w={"50%"}>
          <img
            src={props.imgUrl}
            style={{
              height: "50px",
              width: "50px",
            }}
          ></img>
          <Text fz={18}>{props.title}</Text>
        </Group>
        <Stack
        spacing={1}
        >
        <TextInput
          value={videoUrl}
          onChange={(e)=>{
            setvideoUrl(e.currentTarget.value)
          }}        
        />
        <Flex>
        <Button
        onClick={()=>{
          videoChangeHandler()
        }}
        size="xs"
        >Submit</Button>
        </Flex>
        </Stack>
        <Stack>
          <Button
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            Edit Simualtion Content
          </Button>
          <Flex>
            <TextInput
              value={colorValue}
              onChange={(e) => {
                setColorValue(e.currentTarget.value);
              }}
              style={{
                maxHeight: "100px",
              }}
            />
            {/* <textarea
      className="auto-resizable-textarea" // Add your custom CSS class
      placeholder="Type something..."
      value={text}  
      onChange={handleChange}
    /> */}
            <Button
              onClick={() => {
                colorChangeHandler();
              }}
            >
              Submit
            </Button>
          </Flex>
        </Stack>
        <Stack>
          <Checkbox
            checked={isActivityChecked}
            onChange={(event) =>
              setActivityChecked(event.currentTarget.checked)
            }
            label="Activity"
            styles={{
              label: {
                fontSize: 12,
              },
            }}
            size="xs"
          />
          <Checkbox
            checked={isPremiumChecked}
            onChange={(event) => {
              setPremiumChecked(event.currentTarget.checked);
              updateSimulationPremiumById({
                SimulationId: props.id,
                isPremium: event.currentTarget.checked,
              });
            }}
            label="Premium"
            styles={{
              label: {
                fontSize: 12,
              },
            }}
            size="xs"
          />
          <Checkbox
            checked={isSimulationChecked}
            onChange={(event) =>
              setSimulationChecked(event.currentTarget.checked)
            }
            label="Simulation"
            styles={{
              label: {
                fontSize: 12,
              },
            }}
            size="xs"
          />
        </Stack>
        <MultiSelect
          data={props.simulationFilterData}
          styles={
            {
              // wrapper: {
              //   maxWidth: "25vw",
              // },
            }
          }
          value={value}
          onChange={setValue}
          placeholder="Pick all that you like"
        />
        <Flex direction="column">
          <Button variant="filled" size="xs" ml={10} onClick={submitHandler}>
            Submit
          </Button>
          <Button
            size="xs"
            ml={10}
            mt={2}
            color="red"
            onClick={() => {
              setDeleteSimulationd(props.id);
            }}
          >
            Delete
          </Button>
        </Flex>
        <Modal
          centered
          opened={deleteSimulationId !== ""}
          onClose={() => setDeleteSimulationd("")}
          title={`Are you sure you want to delete ${props.title}?`}
          style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
        >
          <DeleteConfirmationDialog
            onConfirmClick={onDeleteClassConfirm}
            onCancelClick={() => {
              setDeleteSimulationd("");
            }}
          />
        </Modal>
      </Flex>
      <Modal
        centered
        opened={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
      >
        <div>
          <ScrollArea h="30vh">
            <Text>Simulation Features</Text>
            {simualtionFeatures.map((field, index) => (
              <div key={index} style={{ marginBottom: "8px" }}>
                <TextInput
                  placeholder="Enter string"
                  value={field}
                  onChange={(event) => {
                    handleInputChange(event.currentTarget.value, index);
                  }}
                />
              </div>
            ))}
          </ScrollArea>
          <Flex justify={"space-evenly"}>
            <Button
              onClick={addField}
              leftIcon={<IconPlus />}
              // disabled={!allFieldsFilled}
            >
              Add Field
            </Button>
          </Flex>
          <Text>Simulation Description</Text>
          <Textarea
            value={simulationDescription}
            onChange={(event) => {
              setSimulaitonDescription(event.currentTarget.value);
            }}
          />
          <ScrollArea h="25vh">
            <Text>Simulation Tags</Text>
            {simulationTags.map((field, index) => (
              <div key={index} style={{ marginBottom: "8px" }}>
                <TextInput
                  placeholder="Enter string"
                  value={field}
                  onChange={(event) => {
                    handleInputChange2(event.currentTarget.value, index);
                  }}
                />
              </div>
            ))}
          </ScrollArea>
          {/* <Flex justify={"space-evenly"}> */}
          <Button
            onClick={addFiel2}
            leftIcon={<IconPlus />}
            // disabled={!allFieldsFilled}
          >
            Add Field
          </Button>
          <Group>
            <Button
              onClick={() => {
                submitHandler2();
              }}
              mt={10}
            >
              Submit
            </Button>
          </Group>
          {/* </Textarea> */}
        </div>
      </Modal>
    </>
  );
};

interface AddSimulationDataDialogProps {
  simulations: SimulationListData[];
  filters: SimulationFilterData[];
  setSimulations: (data: string) => void;
}

export function EditSimulationDataDialog(props: AddSimulationDataDialogProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  return (
    <ScrollArea h="80vh">
      <Stack mb={100}>
        <LoadingOverlay visible={isLoading} />

        {props.simulations.map((x) => {
          return (
            <SimulationComponent
              key={x._id}
              id={x._id}
              title={x.label}
              imgUrl={x.thumbnailUrl}
              currentFilters={x.simultionfilters}
              simulationFilterData={props.filters}
              setLoadingData={setLoading}
              setSimulations={props.setSimulations}
              isActivity={x.isActivity}
              isPremium={x.isSimulationPremium}
              isSimulation={x.isSimulation}
              simulationDescription={x.simulationDescription}
              simulationFeatures={x.simulationfeatures}
              simulationTags={x.simulationTags}
              simulationBackgroundColor={x.simulationBackgroundColor}
              simulationVideoUrl={x.videoUrl}
            />
          );
        })}
      </Stack>
    </ScrollArea>
  );
}
