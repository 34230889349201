import { Container } from "@mantine/core";
import { useNavigate, useParams } from "react-router";
import { TopicList } from "../components/TopicList";

export function ChapterDetail() {
  const params = useParams();
  const navigate = useNavigate();
  let chapterId = params.chapterId;

  function navigateToTopicDetails(id: string, subjectId: string) {
    navigate(`/topic/${id}?subjectId=${subjectId}`);
  }

  return (
    <Container size="xl">
      <TopicList
        chapterId={chapterId}
        onTopicClick={navigateToTopicDetails}
      ></TopicList>
    </Container>
  );
}
