import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface DeleteTopicState {
  loadingState: LoadingState;
  value?: boolean;
  error: any;
}

const initialState: DeleteTopicState = {
  loadingState: LoadingState.IDLE,
  value: false,
  error: null,
};

export const deleteTopicById = createAsyncThunk(
  "Topic/Delete",
  async (id: string) => {
    const response = await axios.delete(
      `/api/v1/topic/${id}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);

export const deleteTopicByIdSlice = createSlice({
  name: "DeleteTopicByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteTopicById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(deleteTopicById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(deleteTopicById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default deleteTopicByIdSlice.reducer;
