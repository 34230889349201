import {
  Container,
  Paper,
  Title,
  Text,
  Group,
  Checkbox,
  Stack,
} from "@mantine/core";
import { QuestionCard } from "./QuestionCard";
import { SubjectiveQuestionCard } from "./SubjectiveQuestionCard";
import { QuestionType } from "../../@types/QuestionTypes.d";

interface QuestionsListProps {
  topic?: Topic | null | undefined;
}
export function SubjectiveQuestionsList(props: QuestionsListProps) {
  function getQuestions(questionType: QuestionType) {
    const questions = props.topic?.subjectiveQuestions.map((item, index) => {
      if (item.type === questionType.type) {
        return <SubjectiveQuestionCard question={item} indexN={index + 1} />;
      }
    });
    return questions;
  }

  return (
    <Container size="xl">
      <Title>Subjective Questions</Title>
      <Stack>
        <Stack>
          <Text fz={23} fw={600}>
            Short Answer:
          </Text>
          {getQuestions(QuestionType.ShortQues)}
        </Stack>
        <Stack>
          <Text fz={23} fw={600}>
            Long answer:
          </Text>
          {getQuestions(QuestionType.LongQues)}
        </Stack>
        <Stack>
          <Text fz={23} fw={600}>
            Fill in the blanks:
          </Text>
          {getQuestions(QuestionType.FillQues)}
        </Stack>
      </Stack>
    </Container>
  );
}
