import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

export const removeSimulationsFromTopic = async (params: {
  topicId: string;
  simulations: string[];
  subjectId: string;
}) => {
  const response = await axios.put(
    `/api/v1/topic/${params.topicId}/removeSimulations/${params.subjectId}`,
    {
      simulations: JSON.stringify(params.simulations),
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;
  return response;
};
