import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

export async function updateSubjectiveQuestionById(params: {
  QuestionId: string;
  questionText: string;
  answerText: string;
}) {
  const response = await axios.put(
    `/api/v1/subjectivequestion/updatequestion/${params.QuestionId}`,
    {
      text: params.questionText,
      answer: params.answerText,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
export async function updateQuestionById(params: {
  QuestionId: string;
  questionText: string;
  answers: { text: string; isCorrect: boolean }[];
}) {
  const response = await axios.put(
    `/api/v1/question/updatequestion/${params.QuestionId}`,
    {
      text: params.questionText,
      answers: params.answers,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
export async function updateSubjectiveQuestionImagesById(params: {
  QuestionId: string;
  questionImage: string;
  answerImage: string;
}) {
  const response = await axios.put(
    `/api/v1/subjectivequestion/updatequestionimages/${params.QuestionId}`,
    {
      questionImage: params.questionImage,
      answerImage: params.answerImage,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
export async function updateQuestionImagesById(params: {
  QuestionId: string;
  questionImage: string;
  answerImages: string[];
}) {
  const response = await axios.put(
    `/api/v1/question/updatequestionimages/${params.QuestionId}`,
    {
      questionImage: params.questionImage,
      answerImages: params.answerImages,
    },
    {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    }
  );

  const status = response.status;

  if (status == 200) {
    const responseData = response.data;
    return responseData;
  }

  return [];
}
