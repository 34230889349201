import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import {
  AddChapterToSubject,
  getAllChaptersForSubject,
  getAllSubjectsForClass,
  getBoardAndClasses,
} from "../features/boardSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/ReduxStore";
import { createSubject } from "../features/subjects/createSubjectSlice";
import { AddSubjectDialog } from "../components/Dialogs/AddSubjectDialog";

export function BoardChapters() {
  const params = useParams();
  let subjectId = params.subjectId;
  const [chapters, setChapters] = useState([]);
  const [subjectName, setsubjectName] = useState([]);

  const [createModalOpened, setModalOpened] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>("");

  const [baseClasses, setBaseClasses] = useState<
    { _id: string; name: string }[]
  >([]);
  const [baseSubjects, setBaseSubjects] = useState<
    { _id: string; name: string }[]
  >([]);
  const [baseChapters, setBaseChapters] = useState<
    { _id: string; name: string }[]
  >([]);
  const [selectedClass, setSelectedClass] = useState<string | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [selectedChapter, setSelectedChapter] = useState<string | null>(null);

  function fetchList() {
    getAllChaptersForSubject(subjectId || "")
      .then((data: any) => {
        setsubjectName(data.name);
        setChapters(data.chapters);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function fetchBaseClasses() {
    getBoardAndClasses("BOD-a7333db1-618f-4909-9c6e-c1fd6c2fabf9")
      .then((data: any) => {
        setBaseClasses(data.classes);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchList();
    fetchBaseClasses();
  }, []);
  useEffect(() => {
    if (selectedClass) {
      getAllSubjectsForClass(selectedClass)
        .then((data: any) => {
          setBaseSubjects(data.subjects);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedClass) {
      if (selectedSubject)
        getAllChaptersForSubject(selectedSubject)
          .then((data: any) => {
            setBaseChapters(data.chapters);
          })
          .catch((e) => {
            console.log(e);
          });
    }
  }, [selectedSubject]);
  return (
    <>
      <Box mx={50} mt={50}>
        <Button
          onClick={() => {
            setModalOpened(true);
          }}
        >
          Add Chapter
        </Button>
        <Text fz={36} fw={700}>
          {subjectName}
        </Text>
        <Modal
          opened={createModalOpened}
          onClose={() => setModalOpened(false)}
          centered
          title="Add Chapter"
        >
          <Flex my={20}>
            <Select
              label="Class"
              placeholder="Select Class"
              data={baseClasses.map((class1) => {
                return { value: class1._id, label: class1.name };
              })}
              onChange={(value) => {
                setSelectedClass(value);
                setSelectedSubject(null);
                setSelectedChapter(null);
              }}
            />
            <Select
              label="Subject"
              placeholder="Select Subject"
              data={
                baseSubjects.map((sub) => {
                  return { value: sub._id, label: sub.name };
                }) || []
              }
              value={selectedSubject}
              onChange={(value) => {
                setSelectedSubject(value);
                setSelectedChapter(null);
              }}
              mx={10}
            />
            <Select
              label="Select Chapter"
              placeholder="Select Chapter"
              data={
                baseChapters.map((ch) => {
                  return { value: ch._id, label: ch.name };
                }) || []
              }
              value={selectedChapter}
              onChange={(value) => {
                setSelectedChapter(value);
              }}
            />
          </Flex>
          <Button
            onClick={() => {
              if (selectedChapter) {
                setModalOpened(false);
                AddChapterToSubject(subjectId || "", selectedChapter)
                  .then(() => fetchList())
                  .catch((e) => console.log(e));
              }
            }}
          >
            Submit
          </Button>
        </Modal>
        <SimpleGrid cols={3} mt={50} mx={20}>
          {chapters.map((class1: any) => {
            return (
              <>
                <Button>{class1.name}</Button>
              </>
            );
          })}
        </SimpleGrid>
      </Box>
    </>
  );
}
