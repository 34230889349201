import { QuestionType } from "../../@types/QuestionTypes";
import {
    Flex,
    Button,
    TextInput,
    Text
} from '@mantine/core'
interface AddSubjectiveQuestionProps{
    onSubmit: (data: AddSubjectiveQDialogResultData) => void;
}
export const AddSubjectiveQuestionDialog=(props:AddSubjectiveQuestionProps)=>{
    async function formHandler(event: any) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObj: any = Object.fromEntries(formData.entries());
        props.onSubmit(formDataObj)
      }
    return(
        <Flex p={10} direction="column">
          <Text fz={20} fw={700} mb={10}>
            Add Question:
          </Text>
          <form onSubmit={formHandler}>
            <TextInput
              mb={10}
              placeholder="Question"
              name="text"
            />
            <textarea
              style={{
                height: "50vh",
                width: "100%",
                outline: "none",
              }}
              placeholder="Answer"
              name="answer"
            />
            <Button type="submit" my={15}>
              Submit
            </Button>
          </form>
        </Flex>
    )
}