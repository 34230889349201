import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";
import axios from "axios";
import { GetValueFromLocalStorage, LocalStorageKey } from "../../utilities/LocalstorageUtility";

interface SubjectListState {
  loadingState: LoadingState;
  value: ClassModel[];
  error: any;
}

const initialState: SubjectListState = {
  loadingState: LoadingState.IDLE,
  value: [],
  error: null,
};

export const fetchSubjectList = createAsyncThunk(
  "Subject/fetch-all",
  async (classId: string, dispatch) => {
    const response = await axios.get(
      `/api/v1/subject/class/${classId}`,
      {
        headers: {
          authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
        },
      }
    );

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;
      return responseData;
    }

    return [];
  }
);

export const subjectSlice = createSlice({
  name: "Subject",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubjectList.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(fetchSubjectList.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(fetchSubjectList.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default subjectSlice.reducer;
