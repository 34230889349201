import {
  ActionIcon,
  Button,
  Container,
  Flex,
  Group,
  LoadingOverlay,
  Menu,
  Modal,
  Select,
  SimpleGrid,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { RoundedCard } from "../components/RoundedCard";
import { TitleWithMoreButton } from "../components/TitleWithMoreButton";
import { fetchClassList } from "../features/classes/classSlice";
import { createClass } from "../features/classes/createClassSlice";
import { AppDispatch } from "../store/ReduxStore";
import { AddClassDialog } from "../components/Dialogs/AddClassDialog";
import { IconDots, IconExchange, IconPlus } from "@tabler/icons";
import { ShowImageUrlModal } from "../components/UploadImageModals/ShowImageUrlModal";
import { UploadImageModal } from "../components/UploadImageModals/UploadImageModal";
import { TopicDetailsAddMenu } from "../components/TopicDetailsAddMenu";
import {
  create3dSimulation,
  createSimulation,
} from "../features/simulation/createSimulationSlice";
import { CreateSimulationDataModal } from "../components/TopicDetails/CreateSimulationDataModal";
import { EditSimulationDataModal } from "../components/TopicDetails/EditSimulation";
import { AddSimulationFilterDialog } from "../components/Dialogs/AddSimulationFilterDialog";
import { AddSimulationFilterModal } from "../components/TopicDetails/AddSimulationFilter";
import { updateClassById } from "../features/classes/updateClassSlice";
import { DeleteConfirmationDialog } from "../components/Dialogs/DeleteConfirmationDialog";
import { deleteClassById } from "../features/classes/deleteClassByIdSlice";
import { authorizationModel } from "../store/adminAuthorizationSlice";
import { AllBlogsModal } from "../components/TopicDetails/Modals/AllBlogsModal";
import { Three3DSimualiton } from "../components/TopicDetails/3dSimulationModal";
import { getAllBoards } from "../features/boardSlice";
const adminAuthorizationActions = authorizationModel.actions;

export function Classes() {
  const [classes, setClasses] = useState<ClassModel[]>();
  const [fiteredClasses, setFilteredClasses] = useState<ClassModel[]>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showUploadImageDialog, setShowUploadImageDialog] =
    useState<boolean>(false);
  const [showUploadFileUrlDialog, setShowUploadFileUrlDialog] =
    useState<boolean>(false);
  const [fileUploadUrl, setFileUploadUrl] = useState<string | null>(null);
  const [showSimulationDialog, setShowSimulationDialog] =
    useState<boolean>(false);
  const [showSimulationEditDialog, setShowSimulationEditDialog] =
    useState<boolean>(false);
  const [showFilterAddDialog, setShowFilterAddDialog] =
    useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<string>("");
  const [show3dsimualtion, setshow3dSimualtion] = useState<boolean>(false);
  const [showBlogs, setShowBlogs] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [boards, setBoards] = useState<any>([]);
  const [selectedBoard, setSelectedBoard] = useState<any>();
  function fetchAllBoards() {
    getAllBoards()
      .then((data: any) => {
        if (data) {
          setBoards(
            data.map((x: any) => {
              return {
                label: x.name,
                value: x._id,
              };
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  useEffect(() => {
    fetchAllBoards();
  }, []);

  useEffect(() => {
    if (selectedBoard)
      setFilteredClasses(classes?.filter((x) => x.boardId === selectedBoard));
  }, [selectedBoard, classes]);

  const classCard = fiteredClasses?.map((item) => (
    <RoundedCard
      key={item._id}
      title={item.name}
      onClick={() => onClassClick(item)}
      backgroundColor={theme.colors.violet[3]}
      showMenuButton={true}
      onRenameSubmit={(val) => {
        DidConfirmRename({
          _id: item._id,
          name: val,
          order: item.sortOrder,
        });
      }}
      onDeleteClick={() => {
        setShowDeleteDialog(item._id);
      }}
    ></RoundedCard>
  ));

  function DidConfirmRename(data: {
    _id: string;
    name: string;
    order: number;
  }) {
    if (data._id == null) return;
    setLoadingData(true);
    dispatch(
      updateClassById({
        classId: data._id,
        className: data.name,
        order: data.order,
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setClasses((prev) => {
          const prev1 = prev?.map((x) => {
            if (x._id === data._id) {
              return data;
            }
            return x;
          });
          return prev1;
        });
      })
      .catch((error) => {
        setLoadingData(false);
        console.log(error);
      });
  }

  function onDeleteClassConfirm() {
    setLoadingData(true);
    dispatch(deleteClassById(showDeleteDialog))
      .unwrap()
      .then(() => {
        console.log("Deleted");
        setLoadingData(false);
        setClasses((prev) => {
          const prev1 = prev?.filter((x) => {
            return x._id !== showDeleteDialog;
          });
          return prev1;
        });
        setShowDeleteDialog("");
      })
      .catch((error) => {
        setShowDeleteDialog("");
        setLoadingData(false);
        dispatch(adminAuthorizationActions.setModal(true));
        console.log(error);
      });
  }

  const menuItems = [
    <Menu.Item onClick={onAddClick} color="green" icon={<IconPlus size={14} />}>
      Add
    </Menu.Item>,
  ];

  function onClassClick(classModel: ClassModel) {
    navigate(`/class/${classModel._id}`);
  }

  useEffect(() => {
    fetchData();
  }, []);

  function onAddClick() {
    setShowDialog(true);
  }

  function DidClickSubmit(className: string, order: string) {
    setShowDialog(false);
    if (className == null || className.length <= 0) {
      return;
    }
    dispatch(
      createClass({
        className: className,
        order: Number(order),
        boardId: selectedBoard,
      })
    )
      .then((data) => {
        setLoadingData(false);
        fetchData();
      })
      .catch((error) => {
        setLoadingData(false);
        fetchData();
      });
  }

  function fetchData() {
    setLoadingData(true);
    dispatch(fetchClassList())
      .unwrap()
      .then((data) => {
        setLoadingData(false);
        setClasses(data);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }

  function DidSubmitSimulationData(data: {
    name: string;
    loaderFile?: File | null | undefined;
    dataFile?: File | null | undefined;
    frameworkFile?: File | null | undefined;
    codeFile?: File | null | undefined;
    thumbnailImage?: File | null | undefined;
  }) {
    setShowSimulationDialog(false);

    const loaderFile = data.loaderFile;
    const dataFile = data.dataFile;
    const frameworkFile = data.frameworkFile;
    const codeFile = data.codeFile;
    const thumbnailImage = data.thumbnailImage;

    setLoadingData(true);
    dispatch(
      createSimulation({
        name: data.name,
        loaderFile: loaderFile,
        dataFile: dataFile,
        frameworkFile: frameworkFile,
        codeFile: codeFile,
        thumbnailImage: thumbnailImage,
      })
    )
      .unwrap()
      .then((data) => {
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
      });
  }
  function DidSubmit3dSimulationData(data: {
    name: string;
    modelUrl: string;
    thumbnailUrl: string;
  }) {
    setShowSimulationDialog(false);
    setLoadingData(true);
    dispatch(
      create3dSimulation({
        name: data.name,
        modelUrl: data.modelUrl,
        thumbnailUrl: data.thumbnailUrl,
      })
    )
      .unwrap()
      .then((data) => {
        setshow3dSimualtion(false);
        console.log(data);
        setLoadingData(false);
      })
      .catch((error) => {
        setshow3dSimualtion(false);
        setLoadingData(false);
      });
  }

  return (
    <Container size="xl">
      <Group position="apart" m="lg">
        <Title>Classes</Title>
        <Group>
          <Button
            variant="outline"
            onClick={() => {
              navigate("/uploadword");
            }}
          >
            Add Word files
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              navigate("/questionsUploading");
            }}
          >
            Add New Mcqquestion
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              navigate("/transferQuestions");
            }}
          >
            Transfer Questions
          </Button>
          <Button variant="outline" onClick={() => setShowBlogs(true)}>
            Blogs
          </Button>
          <Button
            variant="outline"
            onClick={() => setShowFilterAddDialog(true)}
          >
            Add Simulation Filter
          </Button>
          <Button
            variant="outline"
            onClick={() => setShowSimulationEditDialog(true)}
          >
            Edit Simulation
          </Button>
          <Button
            variant="outline"
            onClick={() => setShowSimulationDialog(true)}
          >
            Upload Simulation
          </Button>
          <Button variant="outline" onClick={() => setshow3dSimualtion(true)}>
            Add 3D
          </Button>
          <Button
            variant="outline"
            onClick={() => setShowUploadImageDialog(true)}
          >
            Upload Image
          </Button>
          <Button variant="filled" onClick={onAddClick}>
            Add Class
          </Button>
        </Group>
      </Group>
      <Flex justify="right">
        <Select
          data={boards}
          value={selectedBoard}
          onChange={(val) => {
            setSelectedBoard(val);
          }}
        />
      </Flex>

      <SimpleGrid cols={3}>{classCard}</SimpleGrid>

      <Modal
        centered
        opened={showDialog}
        onClose={() => setShowDialog(false)}
        title="Add Class"
      >
        <AddClassDialog
          onSubmitClick={(className, order) => DidClickSubmit(className, order)}
        />
      </Modal>
      <Modal
        centered
        opened={showDeleteDialog !== ""}
        onClose={() => setShowDeleteDialog("")}
        title={`Are you sure you want to delete the class?`}
        style={{ fontSize: 22, fontWeight: 900, textAlign: "center" }}
      >
        <DeleteConfirmationDialog
          onConfirmClick={onDeleteClassConfirm}
          onCancelClick={() => {
            setShowDeleteDialog("");
          }}
        />
      </Modal>
      <UploadImageModal
        opened={showUploadImageDialog}
        onClose={() => setShowUploadImageDialog(false)}
        onUploadSuccess={(url) => {
          setShowUploadImageDialog(false);
          setFileUploadUrl(url);
          setShowUploadFileUrlDialog(true);
        }}
      />

      <CreateSimulationDataModal
        opened={showSimulationDialog}
        onClose={() => setShowSimulationDialog(false)}
        onSubmit={DidSubmitSimulationData}
      />

      <Three3DSimualiton
        opened={show3dsimualtion}
        onClose={() => setshow3dSimualtion(false)}
        onSubmit={DidSubmit3dSimulationData}
      />

      <EditSimulationDataModal
        opened={showSimulationEditDialog}
        onClose={() => setShowSimulationEditDialog(false)}
        onSubmit={DidSubmitSimulationData}
      />

      <AddSimulationFilterModal
        opened={showFilterAddDialog}
        onClose={() => setShowFilterAddDialog(false)}
      />

      <ShowImageUrlModal
        opened={showUploadFileUrlDialog}
        onClose={() => setShowUploadFileUrlDialog(false)}
        urlValue={fileUploadUrl ?? null}
      />

      <Modal
        opened={showBlogs}
        onClose={() => {
          setShowBlogs(false);
        }}
        size="lg"
      >
        <AllBlogsModal />
      </Modal>
      <LoadingOverlay visible={loadingData}></LoadingOverlay>
    </Container>
  );
}
