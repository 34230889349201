import { Modal } from "@mantine/core";
import { AddQuestionDialog } from "../../Dialogs/AddQuestionDialog";
import { AddSubjectiveQuestionDialog } from "../../Dialogs/AddSubjectiveQDialog";
import { QuestionType } from "../../../@types/QuestionTypes.d";

interface AddQuestionsModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: AddSubjectiveQDialogResultData) => void;
  type: QuestionType | null;
}

export function AddSubjectiveQuestionModal(props: AddQuestionsModalProps) {
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="xl"
      title={`Add ${props.type?.name ?? ""}`}
      overflow="inside"
      centered
    >
      <AddSubjectiveQuestionDialog onSubmit={props.onSubmit} />
    </Modal>
  );
}
