import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoadingState } from "../LoadingState.d";

import axios from "axios";
import {
  GetValueFromLocalStorage,
  LocalStorageKey,
} from "../../utilities/LocalstorageUtility";

interface ChapterState {
  loadingState: LoadingState;
  value?: Chapter;
  error: any;
}

const initialState: ChapterState = {
  loadingState: LoadingState.IDLE,
  value: undefined,
  error: null,
};

export const fetchChapterById = createAsyncThunk(
  "subject/fetchChapter",
  async (chapterId: string) => {
    const response = await axios.get(`/api/v1/chapter/${chapterId}`, {
      headers: {
        authorization: GetValueFromLocalStorage(LocalStorageKey.Token),
      },
    });

    const status = response.status;

    if (status == 200) {
      const responseData = response.data;

      return responseData;
    }

    return [];
  }
);

export const chapterByIdSlice = createSlice({
  name: "ChapterByIdSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChapterById.pending, (state, action) => {
        state.loadingState = LoadingState.REQUESTED;
        state.error = null;
      })
      .addCase(fetchChapterById.fulfilled, (state, action) => {
        state.value = action.payload;
        state.loadingState = LoadingState.DATA_FETECHED;
        state.error = null;
      })
      .addCase(fetchChapterById.rejected, (state, action) => {
        state.loadingState = LoadingState.ERROR;
        state.error = action.error;
      });
  },
});

export default chapterByIdSlice.reducer;
