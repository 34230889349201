import {
  Box,
  Button,
  Container,
  Modal,
  Paper,
  Textarea,
  Title,
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";

interface TheoryComponentProps {
  topic?: Topic | null | undefined;
}
export function TheoryComponent(props: TheoryComponentProps) {
  return (
    <Container size="xl">
      <Title>Theory</Title>
      <Textarea
        m="xl"
        disabled
        value={props.topic?.theoryUrl}
        autosize
      ></Textarea>
    </Container>
  );
}
export function TheoryNewComponent(props: TheoryComponentProps) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = props.topic?.revisedTheory || "";
  const images = tempElement.querySelectorAll("img");
  images.forEach((image) => {
    image.setAttribute("width", "300");
    image.setAttribute("height", "250");
  });
  const modifiedHtml = tempElement.innerHTML;
  return (
    <Container size="xl">
      <Title>Theory new</Title>
      <Box style={{ border: "1px red solid" }}>
        <div dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
      </Box>
    </Container>
  );
}
export function ReviseTheoryModal(props: {
  opened: boolean;
  theory: string;
  onClose: () => void;
  onSubmit: (text: string) => void;
}) {
  const [theory, setTheory] = useState(props.theory);

  useEffect(()=>{
    setTheory(props.theory)
  },[props.theory])
  return (
    <Modal opened={props.opened} onClose={props.onClose} centered size="xl">
      <Textarea
        label="Input label"
        autosize
        minRows={25}
        value={theory}
        onChange={(event) => setTheory(event.currentTarget.value)}
      ></Textarea>
      <Button
        onClick={() => {
          props.onSubmit(theory);
        }}
      >
        Submit
      </Button>
    </Modal>
  );
}
