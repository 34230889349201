import { Modal } from "@mantine/core";
import { EditSimulationDataDialog } from "../Dialogs/EditSimulationDialog";
import {
    Button,
    FileInput,
    Stack,
    TextInput,
    useMantineTheme,
    Flex,
    ScrollArea,
    Text,
    MultiSelect,
    Group,
    LoadingOverlay,
  } from "@mantine/core";
  import { getSimulationList } from "../../features/simulation/getSimulationListSlice";
  import { getSimulationFilters } from "../../features/simulation/getSimulationSlice";
import {useState,useEffect, Fragment} from 'react'
import { AppDispatch } from "../../store/ReduxStore";
import { useDispatch } from "react-redux";
import { AddSimulationFilterDialog } from "../Dialogs/AddSimulationFilterDialog";
interface AddSimulationFilterProps {
  opened: boolean;
  onClose: () => void;
}

export function AddSimulationFilterModal(props: AddSimulationFilterProps) {   
  return (
    <Fragment>
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      size="lg"
      title="Add Simulation Filter"
      overflow="inside"
      centered
    >
        <AddSimulationFilterDialog 
            onClose={props.onClose}
        />
    </Modal>
    </Fragment>
  );
}
